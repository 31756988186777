import './styles.css';
import { useTheme } from '../ThemeContext';
import { userStore } from '../userStore';
const Switch = () => {
  const { theme, toggleTheme } = useTheme();
  const language = userStore((state) => state.language);
  const setLanguage = (lang) => userStore.setState({ language: lang });
  return (
    <>
      <section
        style={{
          direction: language !== 'ar' ? 'rtl' : 'ltr',
        }}
      >
        <label className="switch">
          <input
            type="checkbox"
            checked={theme === 'light'}
            onChange={() => {
              localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');
              toggleTheme();
            }}
          />
          <span className="slider round" />
        </label>
        <label className="language">
          <input
            type="checkbox"
            checked={language === 'en'}
            onChange={() => {
              localStorage.setItem('language', language === 'ar' ? 'en' : 'ar');
              setLanguage(language === 'ar' ? 'en' : 'ar');
            }}
          />

          <span className="slider round" />
        </label>
      </section>
    </>
  );
};

export default Switch;
