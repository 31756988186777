/* eslint-disable react/no-unknown-property */
import React, { Component, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './Message.css';
import { create_slug, getMessages, userStore } from '../../core/userStore';
import Loading from '../../core/Loading';
import Message from '../message/Message';
import { translator } from '../../core/languages/translator';
import ItemStatusUpdate from './itemstatus';
import { socket } from '../../core/socket/Wsocket';

export default function Messages(props) {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState(0);
  const [item_id, setitem_id] = useState(0);
  const [title, settitle] = useState('');
  const [owner, setOwner] = useState('');
  const [selectedMessage, setselectedMessage] = useState([]);
  const [inboxList, setinboxList] = useState([]);
  const sender = userStore((state) => state.userId);
  const senderName = userStore((state) => state.senderName);
  const messages = userStore((state) => state.messages);
  const newMessage = userStore((state) => state.newmessage);
  const [messageValue, setMessageValue] = useState([]);
  const [activeTab, setactiveTab] = useState([]);
  const [info, setInfo] = useState([]);
  const bottomEl = useRef(null);
  const topEL = useRef(null);

  useEffect(() => {
    if (loading && sender > 0) {
      getMessages();
    }
  }, [loading]);
  function mergeMessages(data) {
    // Reduce the array based on shared options of 'option1' and 'option2'
    return data.reduce((result, obj) => {
      // Find an existing entry in the result array with the same options
      const existingEntry = result.find(
        (entry) =>
          (entry.sender.includes(obj.sender) &&
            entry.reciever.includes(obj.reciever) &&
            entry.item_id === obj.item_id) ||
          (entry.reciever.includes(obj.sender) &&
            entry.sender.includes(obj.reciever) &&
            entry.item_id === obj.item_id)
      );

      if (existingEntry) {
        existingEntry.offer_id?.push(obj.id);
        existingEntry.messages.push(obj.message);
        existingEntry.prices.push(obj.price);
        existingEntry.reciever.push(obj.reciever);
        existingEntry.sender.push(obj.sender);
        existingEntry.currency.push(obj.currency);
        existingEntry.created_at.push(obj.created_at);
        existingEntry.senderNames.push(obj.senderName);
        (existingEntry.senderId = obj.sender !== sender ? obj.sender : existingEntry.senderId),
          (existingEntry.senderName =
            obj.senderName !== senderName ? obj.senderName : existingEntry.senderName);
      } else {
        result.push({
          offer_id: [obj.id],
          item_id: obj.item_id,
          senderId: obj.sender,
          senderName: obj.senderName !== senderName ? obj.senderName : 'You',
          senderNames: [obj.senderName],
          sender: [obj.sender],
          created_at: [obj.created_at],
          currency: [obj.currency],
          title: obj.title,
          prices: [obj.price],
          reciever: [obj.reciever],
          messages: [obj.message],
        });
      }
      return result;
    }, []);
  }
  useEffect(() => {
    setLoading(true);
    setinboxList(mergeMessages(messages));
    setTimeout(() => {
      onMessageClick(
        selectedMessage?.item_id,
        selectedMessage?.senderId,
        selectedMessage?.title,
        selectedMessage?.senderName
      );
      setLoading(false);
    }, 300);
  }, [messages]);
  useEffect(() => {
    setTimeout(() => {
      bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, [messageValue]);

  const scrolTop = () => {
    topEL?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const onMessageClick = (item_id, senderId, title, senderNameT) => {
    setMessageValue([]);
    setactiveTab(title + senderNameT + senderId + item_id);
    let index = inboxList.findIndex(
      (y) =>
        y?.item_id === item_id &&
        y?.title === title &&
        y?.senderName === senderNameT &&
        y?.senderId === senderId
    );
    const x = inboxList[index];
    setMessageValue(
      <div
        className="message d-block"
        key={x?.title + senderNameT + Math.random()}
        id={create_slug(`${x?.title}-${x?.item_id}`)}
      >
        {x?.messages.map((msg, msgindex) => (
          <div
            className={`border text-white rounded-3 mb-3 p-2 ${
              x?.sender[msgindex] === sender ? 'text-start bg-dark ' : 'text-end bg-success'
            }`}
          >
            <p>
              <b>
                {x?.sender[msgindex] === sender ? 'You' : x?.senderNames[msgindex]} -{' '}
                <small>{new Date(x?.created_at[msgindex]).toGMTString()}</small>
              </b>
            </p>
            <p>
              {translator('Price')}:
              <b>
                {x?.prices[msgindex]} {x?.currency[msgindex]}
              </b>
            </p>
            {msg}
          </div>
        ))}
      </div>
    );
    setInfo({
      offer_id: x?.offer_id[x?.offer_id?.length - 1],
      item_id: item_id,
      title: title,
      senderId: x?.senderId,
      currency: x?.currency[x?.currency.length - 1],
      created_at: x?.created_at[x?.created_at.length - 1],
      price: x?.prices[x?.prices.length - 1],
      senderName: senderName,
    });
  };
  return loading ? (
    <Loading width={'100%'} />
  ) : sender > 0 && messages && messages.length ? (
    <>
      <div className="container-fluid text-dark bg-light " id="messages-tabContent">
        <div className="row align-items-start" messageslist={messages}>
          <div ref={topEL}></div>
          <section className="col-md-4 list bg-light d-block">
            <h2>{translator('Inbox')}</h2>
            <hr />
            <div className="list-group">
              {inboxList.length > 0 ? (
                inboxList.map((x, index) => (
                  <button
                    key={x?.title + index + Math.random()}
                    className={` ${
                      activeTab === x?.title + x?.senderName + x?.senderId + x?.item_id
                        ? 'list-group-item list-group-item-action active'
                        : 'list-group-item list-group-item-action '
                    }`}
                    onClick={() => {
                      setselectedMessage(x);
                      setInfo({
                        offer_id: x?.offer_id[x?.offer_id?.length - 1],
                        item_id: item_id,
                        title: x?.title,
                        senderId: x?.senderId,
                        price: x?.prices,
                        currency: x?.currency,
                        created_at: x?.created_at,
                        senderName: x?.senderName,
                        senderNames: x?.senderNames,
                      });
                      return onMessageClick(x?.item_id, x?.senderId, x?.title, x?.senderName);
                    }}
                  >
                    {`${x?.senderName} - ${x?.title}`}
                    <p>
                      <small className="badge sm-badge rounded bg-black">
                        #{x?.offer_id?.length}
                      </small>
                    </p>
                  </button>
                ))
              ) : (
                <span>{`${translator('No messages yet')}`}</span>
              )}
            </div>
          </section>
          <section className="col-md-8 border d-block">
            <div id="messagesReply">
              <h2>{translator('Message body')}</h2>
              <hr />

              {messageValue}
            </div>

            {info.item_id ? (
              info.item_id && (
                <>
                  {
                    <ItemStatusUpdate
                      itemid={info.item_id}
                      offerid={info.offer_id}
                      price={info.price}
                    />
                  }
                  <div id="replymsg">
                    <Message
                      messageSentCallback={() => {
                        getMessages();
                      }}
                      direct={true}
                      item_id={info.item_id}
                      title={info.title}
                      owner={info.senderId}
                      price={info.price}
                      currency={info.currency}
                      created_at={info.created_at}
                      senderName={senderName}
                      senderNames={info?.senderNames}
                    />
                  </div>
                  <div ref={bottomEl}></div>
                  <span className="button-85 p-2" onClick={scrolTop}>
                    {' '}
                    <span dangerouslySetInnerHTML={{ __html: '&#8682;' }} />
                  </span>
                </>
              )
            ) : (
              <span>{`${translator('No messages yet')}`}</span>
            )}
          </section>
        </div>
      </div>
    </>
  ) : sender > 0 ? (
    []
  ) : (
    <a href="/login">{`${translator('Login')}`}</a>
  );
}
