// eslint-disable-next-line react/prop-types
import { en } from './english';
import { ar } from './arabic';
import { userStore } from '../userStore';

function translateAll(str) {
  //  create a dictionary
  const data = str.toLowerCase();
  const final = ar[en.indexOf(data)] ? data.replaceAll(data, ar[en.indexOf(data)]) : str;
  return final;
}

export const translator = (text, lang = false) => {
  let language = lang || userStore.getState().language;
  if (text && typeof text === 'string') {
    switch (language) {
      case 'ar':
        return translateAll(text);
      default:
        return text;
    }
  } else {
    return text;
  }
};
