import styles from './styles.module.css';
import SendMessage from './send-message';
import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { userStore } from '../../userStore';
import Loading from '../../Loading';
import RecievedMessages from './RecievedMessages';
import RoomAndUsers from './room-and-users';
import { SocketContext } from '../../socket/Wsocket';
//   const socket = useContext(SocketContext);

const Chat = (props) => {
  const socket = useContext(SocketContext);
  const [room, setRoom] = useState('');
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const userId = userStore((s) => s.userId);
  const username = userStore((s) => s.senderName);
  useEffect(() => {
    setMounted(true);
    socket.removeAllListeners('join_room');
    if (params?.get('room') !== '' && userId > 0) {
      socket?.emit('join_room', { username: username, room: params?.get('room'), user_id: userId });
      setRoom(params?.get('room'));
    }
  }, [socket]);

  if (!mounted) {
    return <></>;
  }
  return loading ? (
    <Loading />
  ) : (
    <div className="row">
      <div className="col-md-2">
        <RoomAndUsers username={username} room={room} />
      </div>
      <div className="col-md-10">
        <RecievedMessages username={username} room={room} />
        <SendMessage username={username} room={room} />
      </div>
    </div>
  );
};

export default Chat;
