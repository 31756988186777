const ar = [
  'تسجيل الدخول',
  'اشتراك',
  'الصفحة الرئيسية',
  'كتاب بسعر أقل',
  'حقوق النشر',
  'بريد إلكتروني',
  'تسجيل الدخول',
  'اشتراك',
  'اتصل بنا',
  'فشل',
  'كلمة المرور',
  'خنق',
  'اسم المستخدم',
  'سابق',
  'التالي',
  'قبلت',
  'مقبول إذا',
  'عنوان url نشط',
  'بعد',
  'مجموعة مصفوفة',
  'قبل',
  'بين',
  'يبيع',
  'منطقي',
  'مؤكد',
  'كلمة السر الحالية',
  'تاريخ',
  'التاريخ يساوي',
  'مختلف',
  'ينتهي بـ',
  'موجود',
  'ملف',
  'رفع',
  'مملوء',
  'موقع',
  'دولة',
  'ip',
  'أحرف صغيرة',
  'التمثيل الصامت',
  'ليس في',
  'مطلوب',
  'نفس',
  'مقاس',
  'خيط',
  'بالفعل',
  'فريد',
  'تم الرفع',
  'url',
  'عنوان',
  'سبب',
  'تسجيل خروج',
  'الصفحة الرئيسية',
  'خلف',
  'إلى الأمام',
  'ابحث الآن',
  'بريد إلكتروني',
  'بريد إلكتروني',
  'بريد',
  'مدينة',
  'هاتف',
  'رقم',
  'امتحان',
  'انقر',
  'يستخدم',
  'يحصل',
  'بريد',
  'يشتري',
  'يصنع',
  'يعرض',
  'اسم المستخدم',
  'اسم',
  'أولاً',
  'آخر',
  'تأكيد',
  'مدينة',
  'دولة',
  'عنوان',
  'هاتف',
  'متحرك',
  'عمر',
  'الجنس',
  'جنس',
  'يوم',
  'شهر',
  'سنة',
  'ساعة',
  'دقيقة',
  'ثانية',
  'محتوى',
  'وصف',
  'مقتطفات',
  'تاريخ',
  'وقت',
  'متاح',
  'مقاس',
  'سعر',
  'وصف',
  'عنوان',
  'ف',
  'وصلة',
  'سبيكة',
  'كتاب ليس',
  'رسالة',
  'إيجار',
  'تقديم عرض',
  'تم الارسال',
  'هل هناك خطب ما',
  'مرسل',
  'موقع',
  'ارسل رسالة',
  'رسالتك',
  'عنوان البريد الإلكتروني',
  'نوع الاستفسار',
  'عنوان البريد الإلكتروني غير صالح',
  'يجب ألا يقل عن 25 حرفًا',
  'تحميل',
  'تأكيد كلمة المرور',
  'الاسم الأول',
  'اسم العائلة',
  'إرسال',
  'حذف',
  'تعديل',
  'تم تسجيل المستخدم بنجاح!',
  'أشيائي',
  'رفع',
  'تم الرفع',
  'اختر صورة',
  'إنشاء إعلان جديد',
  'لا توجد منتجات للبيع بعد',
  'صورة',
  'حالة',
  'عملة',
  'اختار',
  'لا توجد رسائل بعد',
  'رسائلي',
  'سلع أخرى للبيع',
  'غير مصرح به من فضلك',
  'انقر هنا لتسجيل الدخول',
  'انقر هنا',
  'السياسة والشروط',
  // add below
  'مُباع',
  'اللغة الإنجليزية وآدابها',
  'الرياضيات',
  'علوم (فيزياء , كيمياء , أحياء)',
  'العلوم الاجتماعية (التاريخ والجغرافيا والتربية المدنية)',
  'اللغات الأجنبية (الفرنسية , الإسبانية , الألمانية , إلخ.)',
  'دراسات الأعمال / الاقتصاد',
  'علوم الكمبيوتر / تكنولوجيا المعلومات',
  'الفنون والعلوم الإنسانية',
  'دراسات بيئية',
  'روايات',
  'كتب واقعية',
  'النمو الشخصي وتطوير الذات',
  'الأعمال وريادة الأعمال',
  'علم النفس',
  'فلسفة',
  'السفر',
  'الطبخ / الطعام',
  'صحة / لياقة',
  'التعليم / الأسرة',
  'الشعر',
  'الدين / الروحانية',
  'آخر',
  'فئة',
  // countries
  'أفغانستان',
  'ألبانيا',
  'الجزائر',
  'أندورا',
  'أنغولا',
  'أنغيلا',
  'أنتيغوا وأمبير',
  'الأرجنتين',
  'أرمينيا',
  'أروبا',
  'أستراليا',
  'النمسا',
  'أذربيجان',
  'جزر الباهاما',
  'البحرين',
  'بنغلاديش',
  'باربادوس',
  'بيلاروس',
  'بلجيكا',
  'بليز',
  'بنين',
  'برمودا',
  'بوتان',
  'بوليفيا',
  'البوسنة وأمبير. الهرس',
  'بوتسوانا',
  'البرازيل',
  'جزر فيرجن البريطانية',
  'بروناي',
  'بلغاريا',
  'بوركينا فاسو',
  'بوروندي',
  'كمبوديا',
  'الكاميرون',
  'الرأس الأخضر',
  'جزر كايمان',
  'تشاد',
  'تشيلي',
  'الصين',
  'كولومبيا',
  'الكونغو',
  'جزر كوك',
  'كوستا ريكا',
  'كوت د ايفوار',
  'كرواتيا',
  'سفينه سياحيه',
  'كوبا',
  'قبرص',
  'الجمهورية التشيكية',
  'الدنمارك',
  'جيبوتي',
  'دومينيكا',
  'جمهورية الدومينيكان',
  'الاكوادور',
  'مصر',
  'السلفادور',
  'غينيا الإستوائية',
  'استونيا',
  'أثيوبيا',
  'جزر فوكلاند',
  'جزر فاروس',
  'فيجي',
  'فنلندا',
  'فرنسا',
  'بولينيزيا الفرنسية',
  'جزر الهند الغربية الفرنسية',
  'جابون',
  'غامبيا',
  'جورجيا',
  'ألمانيا',
  'غانا',
  'جبل طارق',
  'اليونان',
  'الأرض الخضراء',
  'غرينادا',
  'غوام',
  'غواتيمالا',
  'جيرنزي',
  'غينيا',
  'غينيا بيساو',
  'غيانا',
  'هايتي',
  'هندوراس',
  'هونغ كونغ',
  'هنغاريا',
  'أيسلندا',
  'الهند',
  'إندونيسيا',
  'إيران',
  'العراق',
  'أيرلندا',
  'جزيرة آيل أوف مان',
  'إسرائيل',
  'إيطاليا',
  'جامايكا',
  'اليابان',
  'جيرسي',
  'الأردن',
  'كازاخستان',
  'كينيا',
  'الكويت',
  ' جمهورية قيرغيزستان ',
  'لاوس',
  'لاتفيا',
  'لبنان',
  'ليسوتو',
  'ليبيريا',
  'ليبيا',
  'ليختنشتاين',
  'ليتوانيا',
  'لوكسمبورغ',
  'ماكاو',
  'مقدونيا',
  'مدغشقر',
  'ملاوي',
  'ماليزيا',
  'جزر المالديف',
  'مالي',
  'مالطا',
  'موريتانيا',
  'موريشيوس',
  'المكسيك',
  'مولدوفا',
  'موناكو',
  'منغوليا',
  'الجبل الأسود',
  'مونتسيرات',
  'المغرب',
  'موزمبيق',
  'ناميبيا',
  'نيبال',
  'هولندا',
  'جزر الأنتيل الهولندية',
  'كاليدونيا الجديدة',
  'نيوزيلندا',
  'نيكاراغوا',
  'النيجر',
  'نيجيريا',
  'النرويج',
  'سلطنة عمان',
  'باكستان',
  'فلسطين',
  'بنما',
  'بابوا غينيا الجديدة',
  'باراغواي',
  'بيرو',
  'فيلبيني',
  'بولندا',
  'البرتغال',
  'بورتوريكو',
  'دولة قطر',
  'جمع شمل',
  'رومانيا',
  'روسيا',
  'رواندا',
  ' القديس بيير & أمبير ؛ ميكلون',
  'ساموا',
  'سان مارينو',
  'الأقمار الصناعية',
  'المملكة العربية السعودية',
  'السنغال',
  'صربيا',
  'سيشل',
  'سيرا ليون',
  'سنغافورة',
  'سلوفاكيا',
  'سلوفينيا',
  'جنوب أفريقيا',
  'كوريا الجنوبية',
  'إسبانيا',
  'سيريلانكا',
  'سانت كيتس وأمبير. نيفيس ',
  'شارع لوسيا',
  'شارع فنسنت',
  'شارع. لوسيا',
  'السودان',
  'سورينام',
  'سوازيلاند',
  'السويد',
  'سويسرا',
  'سوريا',
  'تايوان',
  'طاجيكستان',
  'تنزانيا',
  'تايلاند',
  'تيمور ليستي',
  'توجو',
  'تونجا',
  ' ترينيداد وأمبير. توباغو',
  'تونس',
  'ديك رومى',
  'تركمانستان',
  ' الأتراك وأمبير. كايكوس',
  'أوغندا',
  'أوكرانيا',
  'الإمارات العربية المتحدة',
  'المملكة المتحدة',
  'أوروغواي',
  'أوزبكستان',
  'فنزويلا',
  'فيتنام',
  'جزر العذراء (الولايات المتحدة)',
  'اليمن',
  'زامبيا',
  'زيمبابوي',
  'بيع عنصر',
  'عام',
  'الدعم',
  'اتجاه',
  'تحتاج مساعدة',
  'نص الرسالة',
  'الوارد',
  'الرسائل',
  'جديد',
  'مستخدم',
  'مملوكة من قبل',
  'غير مستعمل',
  'عروضي',
  'حالة',
  'قيد الانتظار',
  'مرفوض',
  'قبلت',
  // from here
  'دردشة',
  'إرسال',
  'نشط',
  'على الخط',
  'المستخدمون النشطون',
  'خارج الشبكة',
  'غادر',
  'مغادرة الدردشة',
  'اكتب رسالة',
  'لقد تلقيت رسالة جديدة',
  'أنت',
  'حسابي',
  // دردشة عبر
  'لماذا الدردشة؟',
  'أي سؤال',
  'اي طلب',
  'أبحث عن كتاب',
  'امتلاك كتاب فريد',
  'التدقيق في الكتاب',
  'انت في المكان الصحيح',
  'إرسال رسالة عامة',
  'احصل على إجابتك',
  'نتمنى لك التوفيق في بحثك',
];

export { ar };
