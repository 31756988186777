import socketio from 'socket.io-client';
import { userStore } from '../userStore';
import './Wsocket';
import { createContext } from 'react';
const url = `${process.env.REACT_APP_SOCKET}`;

const socket = socketio.connect(url, {
  autoConnect: true,
  reconnection: true,
  reconnectionDelay: 10000,
  reconnectionDelayMax: 10000,
  reconnectionAttempts: Infinity,
  forceNew: true,
  pingInterval: 24 * 60 * 60 * 1000,
  pingTimeout: 3 * 24 * 60 * 60 * 1000,
});

if (userStore.getState().userId > 0) {
  socket.auth = { user_id: userStore.getState().userId, sessionId: socket.id };
}
const SocketContext = createContext();

export { socket, SocketContext };
