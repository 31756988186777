import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import useSubmit from '../services/useSubmit';
import { ChakraProvider } from '@chakra-ui/react';
import '../sell/Sell.css';
import axios from 'axios';
import { addItem, checkUser, userStore } from '../../core/userStore';
import { Link, Navigate, useParams } from 'react-router-dom';
import { ICountry, IState, ICity } from 'country-state-city';
import { toast } from 'react-toastify';
import { Toast } from 'bootstrap';
import { translator } from '../../core/languages/translator';
import { countries, countryCities, currencies } from '../../core/countries';
import Loading from '../../core/Loading';
import { bookCategories, checkText } from '../../core/Helpers';

export default function Edit(props) {
  const [mounted, setMounted] = useState(false);
  const [item, updateItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = userStore((state) => state.userId);
  const isLoggedIn = userStore((state) => state.loggedIn);
  const email = userStore((state) => state.email);
  const [city, setCity] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState('');
  const [condition, setCondition] = useState('');
  const [prev, setPrev] = useState('');
  const [files, setFiles] = useState([]);
  const [picture, setpicture] = useState('');
  const [location, setlocation] = useState('');
  const { isLoading, response, submit } = useSubmit();
  const [onOpen, setOnOpen] = useState([]);
  const [currency, setCurrency] = useState('');
  const [category, setCategory] = useState('');
  const [country, setCountry] = useState('');
  const isLoggedin = userStore((state) => state.loggedIn);
  const params = useParams();
  const submitFile = () => {
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('user_id', userId);
    return axios.post(`${process.env.REACT_APP_AUTH}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
  useEffect(() => {
    setMounted(true);
    axios.get(`${process.env.REACT_APP_AUTH}/getitem/${params.item_id}`).then((data) => {
      updateItem(data?.data[0]);
      setCity(data?.data[0]?.city);
      setCategory(data?.data[0]?.category || '');
      setCondition(data?.data[0]?.condition);
      setPrice(data?.data[0]?.price);
      setName(data?.data[0]?.name || data?.data[0]?.title);
      setCountry(data?.data[0]?.country);
      setCurrency(data?.data[0]?.currency);
      setPrice(data?.data[0]?.price);
      setCondition(data?.data[0]?.condition);
      setDescription(data?.data[0]?.description);
    });
  }, []);

  useEffect(() => {
    setMounted(mounted);
  }, []);

  let formik = useFormik({
    initialValues: {
      title: '',
      price: 0,
      currency: [],
      condition: '',
      city: '',
      country: [],
      description: '',
      file: [],
      category: [],
    },
    onSubmit: (values) => {
      let finalValues = values;
      finalValues['picture'] = prev;
      finalValues['user_id'] = userId;
      finalValues['item_id'] = item?.item_id;
      finalValues['country'] = country;
      finalValues['condition'] = condition;
      finalValues['currency'] = currency;
      finalValues['status'] = 1;
      finalValues['category'] = category;
      finalValues['price'] = price;
      finalValues['name'] = checkText(name);
      finalValues['title'] = checkText(name);
      finalValues['location'] = `${city}, ${country}`;
      finalValues['country'] = `${country}`;
      finalValues['city'] = `${city}`;
      finalValues['description'] = checkText(description);
      setOnOpen({ message: translator('Loading') });
      userStore.setState({ country: country, currency: currency, city: values['city'] });
      if (prev !== item?.picture) {
        submitFile(values?.file)
          .then((response) => {
            setpicture(response.data.path);
            finalValues['picture'] = response.data.path;
          })
          .then((x) => {
            return submit('updateItem', finalValues);
          })
          .catch((error) => {
            toast(JSON.stringify(error?.message || error));
          });
      } else {
        finalValues['picture'] = item?.picture;
        submit('updateItem', finalValues);
      }
    },
    validationSchema: Yup.object({}),
  });

  useEffect(() => {
    setPrev(`${process.env.REACT_APP_AUTH}/files/${item?.picture}`);
    setLoading(false);
  }, [item?.user_id]);

  useEffect(() => {
    if (response) {
      setOnOpen({ type: response.type, message: translator('Updated') });
      if (response.type === 'success') {
        formik.resetForm();
      }
    }
  }, [response]);
  const handleFileUpload = (e, file = false) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setPrev(fileReader.result);
      }
    };
    setFiles(file ? [{ file }] : e.target.files);

    fileReader.readAsDataURL(file ? file : e.target.files[0]);
  };

  return userId === item?.user_id && !loading ? (
    <>
      <div
        className="container p-5"
        style={{
          // eslint-disable-next-line react/prop-types
          backgroundColor: props.theme === 'dark' ? 'black' : 'white',
          // eslint-disable-next-line react/prop-types
          color: props.theme === 'dark' ? 'white' : 'black',
        }}
      >
        {!isLoading ? (
          <ChakraProvider>
            <VStack alignItems="flex-start">
              <Heading as="h1" id="contactus">
                {translator('Update Item')}
              </Heading>

              <Box p={6} rounded="md" w="100%">
                {onOpen?.message}
                <form onSubmit={formik.handleSubmit}>
                  <VStack spacing={4}>
                    {prev !== '' && <img width={'30%'} height={'30%'} src={prev} />}
                    <FormControl isInvalid={!!formik.errors.file && formik.touched.file}>
                      <FormLabel htmlFor="file"> {translator('Photo')}</FormLabel>
                      <Input
                        id="file"
                        name="file"
                        type="file"
                        onChange={(e) => {
                          handleFileUpload(e);
                        }}
                      />
                      <FormErrorMessage>{formik.errors.file}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.category && formik.touched.category}>
                      <FormLabel htmlFor="category"> {translator('Category')}</FormLabel>
                      <select
                        id="category"
                        icon={<></>}
                        required
                        value={category || ''}
                        // {...formik.getFieldProps('country')}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option key={Math.random()} value={''}>
                          {`${translator(`Select`)} ${translator(`Category`)}`}
                        </option>
                        {bookCategories.map((x) => {
                          return (
                            <option
                              key={x + Math.random()}
                              value={x}
                              {...(category === x ? 'selected' : '')}
                            >
                              {translator(x)}
                            </option>
                          );
                        })}
                      </select>
                      <FormErrorMessage>{formik.errors.category}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.title && formik.touched.title}>
                      <FormLabel htmlFor="title"> {translator('Name')}</FormLabel>
                      <Input
                        id="title"
                        name="title"
                        required
                        type="text"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                        // {...formik.getFieldProps('title')}
                      />
                      <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.condition && formik.touched.condition}>
                      <FormLabel htmlFor="condition"> {translator('Condition')}</FormLabel>
                      <Input
                        id="condition"
                        name="condition"
                        type="text"
                        onChange={(e) => {
                          setCondition(e.target.value);
                        }}
                        value={condition}
                        // {...formik.getFieldProps('condition')}
                      />
                      <FormErrorMessage>{formik.errors.condition}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!formik.errors.price && formik.touched.price}>
                      <FormLabel htmlFor="price"> {translator('Price')}</FormLabel>
                      <Input
                        id="price"
                        name="price"
                        type="number"
                        onChange={(e) => {
                          setPrice(e.target.value);
                        }}
                        value={price}
                        // {...formik.getFieldProps('price')}
                      />
                      <FormErrorMessage>{formik.errors.price}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.currency && formik.touched.currency}>
                      <FormLabel htmlFor="currency">{translator('Currency')}</FormLabel>
                      <Select
                        id="currency"
                        icon={<></>}
                        required
                        value={currency}
                        // {...formik.getFieldProps('country')}
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option key={Math.random()} value={''}>
                          {`${translator(`Select`)} ${translator(`Currency`)}`}
                        </option>
                        {Object.keys(currencies).map((x) => {
                          if (currencies[x]?.name) {
                            return (
                              <option key={currencies[x].name} value={currencies[x].name}>
                                {currencies[x].symbol} - {currencies[x].name}
                              </option>
                            );
                          }
                        })}
                      </Select>
                      <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.country && formik.touched.country}>
                      <FormLabel htmlFor="country">{translator('Country')}</FormLabel>
                      <Select
                        id="country"
                        name="country"
                        required
                        icon={<></>}
                        value={country}
                        // {...formik.getFieldProps('country')}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option key={Math.random()} value={''}>
                          {`${translator(`Select`)} ${translator(`Country`)}`}
                        </option>
                        {countries.map((x) => (
                          <option key={x.label + x.value} value={x.label}>
                            {x.label}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.city && formik.touched.city}>
                      <FormLabel htmlFor="city">{translator('City')}</FormLabel>
                      <Input
                        id="city"
                        type="text"
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        value={city}
                        required
                        name="city"
                        // {...formik.getFieldProps('city')}
                      ></Input>
                      <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={!!formik.errors.description && formik.touched.description}
                    >
                      <FormLabel htmlFor="description">{translator('Description')}</FormLabel>
                      <Textarea
                        id="description"
                        name="description"
                        required
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        value={description}
                        type="textarea"
                        // {...formik.getFieldProps('description')}
                      />
                      <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                    </FormControl>

                    <Button
                      className="button-85 "
                      type="submit"
                      colorScheme="purple"
                      width="full"
                      isLoading={isLoading}
                    >
                      {translator('Submit')}
                    </Button>
                  </VStack>
                </form>
              </Box>
            </VStack>
          </ChakraProvider>
        ) : (
          <Loading width="100%" />
        )}
      </div>
    </>
  ) : (
    <>
      <Loading width="100%" />
    </>
  );
}
