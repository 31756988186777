import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { create_slug, soldPng, userStore } from '../../../core/userStore';
import axios from 'axios';
import { toast } from 'react-toastify';
import { currencies } from '../../../core/countries';
import './styles.css';
import { translator } from '../../../core/languages/translator';
import Loading from '../../../core/Loading';
import useSubmit from '../../services/useSubmit';
import ConfirmationButton from '../../../core/ConfirmationButton';
import CustomModal from '../../../core/CustomModal';
export default function Card(props) {
  const { item, index, nodate, onlyimage, myprofile } = props;
  const userId = userStore((state) => state.userId);
  const [loading, setLoading] = useState(true);
  const [showMenu, setshowMenu] = useState(false);
  const { isLoading, response, submit } = useSubmit();
  const theme = userStore((s) => s.theme);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_AUTH}/delete/${id}`, { user_id: userId })
      .then((x) => {
        toast(translator('Deleted'));
        setLoading(false);
        setTimeout(() => {
          window.location.href = '/profile';
        }, 1000);
      })
      .catch((x) => {
        toast(translator('Something Wrong!'));
        setLoading(false);
        setTimeout(() => {
          window.location.href = '/profile';
        }, 1000);
      });
    setshowMenu(false);
  };
  const handleSold = (id, data) => {
    let results = data;
    results.status = 0;
    submit('updateItem', results)
      .then((x) => {
        toast(translator('Congrats a lot more to sell!'));
      })
      .catch((x) => toast(translator('Something Wrong!')));
    setshowMenu(false);
  };
  useEffect(() => {
    setLoading(false);
  }, []);
  return isLoading ? (
    <Loading />
  ) : (
    <div className="border shadow">
      {myprofile && userId === item?.user_id && (
        <button
          className="nav-item p-2  pt-0"
          data-bs-toggle="modal"
          data-bs-target={`#${create_slug(
            `itemCardEdit-${item?.item_id}-${item?.name}-${item?.status}`
          )}`}
          href={create_slug(`itemCardEdit-${item?.item_id}-${item?.name}-${item?.status}`)}
          role="button"
          onClick={() => setshowMenu(!showMenu)}
          style={{
            // eslint-disable-next-line react/prop-types
            backgroundColor: theme === 'dark' ? 'black' : 'white',
            // eslint-disable-next-line react/prop-types
            color: theme === 'dark' ? 'white' : 'black',
            fontSize: 30,
            width: '100%',
          }}
        >
          <span href="#" dangerouslySetInnerHTML={{ __html: '&#9998;' }}></span>
        </button>
      )}
      <CustomModal
        status={showMenu}
        steps={false}
        title1={translator(item?.name)}
        className={'text-black ' + !showMenu ? 'hide' : ''}
        classid={create_slug(`itemCardEdit-${item?.item_id}-${item?.name}-${item?.status}`)}
        details1={
          <>
            <section className="row">
              <div className="col-12">
                <Link to={`/edit/${item?.item_id}`}>
                  <button
                    style={{ width: '100%' }}
                    type="button"
                    data-bs-dismiss="modal"
                    className=" button-85 sell-btn mt-2"
                  >
                    {translator('Edit')}
                  </button>
                </Link>
              </div>
              <div className="col-12">
                <Link to="/account">
                  <button
                    style={{ width: '100%' }}
                    hidden={item?.status !== '1' ? true : false}
                    type="button"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleSold(item?.item_id, item);
                    }}
                    className=" button-85 sell-btn mt-2"
                  >
                    {translator('Sold')}
                  </button>
                </Link>
              </div>
              <div className="col-12">
                <ConfirmationButton
                  onConfirm={() => {
                    handleDelete(item?.item_id);
                  }}
                  title={translator('Delete')}
                  query={translator('Are you sure...?')}
                />
              </div>
            </section>
          </>
        }
      />
      <a className="card" href={`/item/${item?.item_id}`}>
        <div className="card-image col-md-12 maxw-100 ">
          {item?.status !== '1' ? <img id="sold" src={soldPng} /> : []}
          <img
            width="100%"
            height={onlyimage ? '30%' : 'auto'}
            src={`${process.env.REACT_APP_AUTH}/files/${item?.picture || 'uploads/no-pic.png'}`}
            alt={item?.name}
            style={{
              maxHeight: onlyimage ? '150px' : 'auto',
            }}
          />
          <div className="card-price">
            <p>
              {item?.price}
              {item?.currency && item?.currency !== ''
                ? Object.values(currencies)?.filter((x) => x.name === item?.currency)[0]?.symbol
                : '$'}
            </p>
          </div>
          <div className="card-title">
            {`${item?.name || item?.title} ${item?.status !== '1' ? ' SOLD ' : ''}`}
          </div>
        </div>
        {onlyimage ? (
          []
        ) : (
          <>
            {!nodate ? (
              <div>
                <div className="row maxw-100  ">
                  {isNaN(item?.created_at) == false ? (
                    <small>{new Date(Date.parse(item?.created_at)).toString()}</small>
                  ) : (
                    []
                  )}{' '}
                  <span className="badge bg-dark">
                    {item?.category ? translator(item?.category) : ''}
                  </span>
                  <span className="badge border text-dark">{translator(item?.condition)}</span>
                </div>
              </div>
            ) : (
              []
            )}
            <div className="card-body">
              <p>{item?.description?.substring(0, 50)}</p>
            </div>
            <div className="card-footer">
              <p>
                {translator(item?.city)}, {translator(item?.country)}
              </p>
            </div>
          </>
        )}
      </a>
    </div>
  );
}
