import { checkText } from '../../Helpers';
import Loading from '../../Loading';
import { translator } from '../../languages/translator';
import { SocketContext } from '../../socket/Wsocket';
import { userStore } from '../../userStore';
import styles from './styles.module.css';
import React, { useContext, useState } from 'react';

const SendMessage = ({ username, room }) => {
  const socket = useContext(SocketContext);
  const [message, setMessage] = useState('');
  const user_id = userStore((s) => s.userId);
  const [loading, setLoading] = useState(false);

  const sendMessage = (e) => {
    e.preventDefault();
    setLoading(true);
    if (room !== '' && user_id > 0 && !loading) {
      const created_at = Date.now();
      // Send message to server. We can't specify who we send the message to from the frontend. We can only send to server. Server can then send message to rest of users in room
      socket?.emit('send_message', {
        username: username,
        user_id: user_id,
        room: room,
        message: checkText(message),
        created_at: created_at,
      });
      setMessage('');
      setLoading(false);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <form
      className={`form-group d-flex`}
      onSubmit={(e) => sendMessage(e)}
      disabled={loading ? true : false}
    >
      <input
        className={`form-control  ${styles.messageInput}`}
        placeholder={translator('Message')}
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        id={styles.sendmessage}
      />
      <button className="button-85  ">{translator('Send')}</button>
    </form>
  );
};

export default SendMessage;
