import React, { useEffect } from 'react';
import AuthService from '../components/services/index';
import { Navigate } from 'react-router-dom';

export default function LogOutPage() {
  useEffect(() => {
    AuthService.logout();
    window.location.href = '/';
  }, []);
  return <></>;
}
