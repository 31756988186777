import React from 'react';

function strip(html) {
  if (html === null || html === '') {
    return false;
  } else {
    html = html.toString();
  }
  return html.replace(/(<([^>]+)>)/gi, '');
}

function checkText(text) {
  if (typeof text === 'string' && text !== '') {
    let beforeText = text.replace(/(<([^>]+)>)/gi, '');
    var urlRegex1 =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|<=.+@)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    var urlRegex2 =
      /[a-zA-Z0-9\\+\\.\\_\\%\\-]{1,256}\\@[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}(\\.[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25})+/gi;
    var urlRegex3 =
      /(((https?:\/\/)|(http?:\/\/)|(www\.)|(\.com)|(\.ru)|(\.net))|(\.mobi)|(\.org)|(\.de)|(\.io)|(@)[^\s]+)/gi;
    var phone =
      /((?:\+\d{2}[-.\s]??|\d{4}[-.\s]??)?(?:\d{3}[-.\s]??\d{3}[-.\s]??\d{4}|\(\d{3}\)\s*\d{3}[-.\s]??\d{4}|\d{3}[-.\s]??\d{4}))/gi;
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
    let lvl1 = beforeText.replace(re, function (url) {
      return '***';
    });
    let lvl2 = lvl1.replace(urlRegex2, function (url) {
      return '***';
    });
    let lvl3 = lvl2.replace(urlRegex3, function (url) {
      return '***';
    });
    // let lvl4 = lvl3.replace(phone, function (url) {
    //   return '***';
    // });
    return lvl3.replace(urlRegex1, function (email) {
      return '***';
    });
  } else {
    return text;
  }
}
function checkLink(e) {
  //here you would want to use a regular expression and check for http:

  var regularExpression = !!checkText(e.target.innerHTML); // returns true or false
  if (regularExpression) {
    e.target.innerHTML += `<a href='#'><img src="" alt="" /></a>`;
  }
}

/**
 *
 */
const randomColor = () => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
};

const bookCategories = [
  'English language and literature',
  'mathematics',
  'Science (Physics, Chemistry, Biology)',
  'Social Sciences (History, Geography, Civics)',
  'Foreign languages (French, Spanish, German, etc.)',
  'Business Studies/Economics',
  'Computer Science/Information Technology',
  'Arts and humanities',
  'Environmental studies',
  'Novels',
  'Non-fiction books',
  'Personal growth and self development',
  'Business and entrepreneurship',
  'psychology',
  'Philosophy',
  'travel',
  'cooking/food',
  'Health/Fitness',
  'Education/family',
  'Poetry',
  'Religion/spirituality',
  'other',
];

export { checkLink, checkText, strip, randomColor, bookCategories };
