import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import useSubmit from '../components/services/useSubmit';
import { ChakraProvider } from '@chakra-ui/react';
import { translator } from './languages/translator';
import Loading from './Loading';
import AboutUs from './Aboutus';
/**
 * Covers a complete form implementation using formik and yup for validation
 */
// eslint-disable-next-line no-unused-vars
function ContactUs(props) {
  const { isLoading, response, submit } = useSubmit();
  const [onOpen, setOnOpen] = useState('');

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastname: '',
      email: '',
      type: 'support',
      message: '',
    },
    onSubmit: (values) => {
      values['name'] = `${values['firstName']} ${values['lastname']}`;
      setOnOpen('Loading');
      submit('contactus', values);
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastname: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      message: Yup.string().min(25, 'Must be at least 25 characters').required('Required'),
    }),
  });

  useEffect(() => {
    if (response) {
      setOnOpen(response.type, response.message);
      if (response.type === 'success') {
        formik.resetForm();
      }
    }
  }, [response]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div
        className="container p-5"
        style={{
          // eslint-disable-next-line react/prop-types
          backgroundColor: props.theme === 'dark' ? 'black' : 'white',
          // eslint-disable-next-line react/prop-types
          color: props.theme === 'dark' ? 'white' : 'black',
        }}
      >
        <AboutUs />
        <ChakraProvider>
          <VStack alignItems="flex-start">
            <Heading as="h1" id="contactus">
              {translator('Contact Us')}
            </Heading>
            <Box p={6} rounded="md" w="100%">
              {onOpen}
              <form onSubmit={formik.handleSubmit}>
                <VStack spacing={4}>
                  <FormControl isInvalid={!!formik.errors.firstName && formik.touched.firstName}>
                    <FormLabel htmlFor="firstName">{translator('First name')}</FormLabel>
                    <Input id="firstName" name="firstName" {...formik.getFieldProps('firstName')} />
                    <FormErrorMessage>{formik.errors.firstname}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.lastname && formik.touched.lastname}>
                    <FormLabel htmlFor="lastname">{translator('Last name')}</FormLabel>
                    <Input id="lastname" name="lastname" {...formik.getFieldProps('lastname')} />
                    <FormErrorMessage>{formik.errors.lastname}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                    <FormLabel htmlFor="email">{translator('Email Address')}</FormLabel>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      {...formik.getFieldProps('email')}
                    />
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="type">{translator('Type of enquiry')}</FormLabel>

                    <Select icon={<></>} id="type" name="type" {...formik.getFieldProps('type')}>
                      <option value="support">{translator('General')}</option>
                      <option value="help">{translator('Need Help')}</option>
                      <option value="direction">{translator('Direction')}</option>
                      <option value="direction">{translator('Support')}</option>
                    </Select>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.message && formik.touched.message}>
                    <FormLabel htmlFor="message">{translator('Your message')}</FormLabel>
                    <Textarea
                      id="message"
                      name="message"
                      height={250}
                      {...formik.getFieldProps('message')}
                    />
                    <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                  </FormControl>
                  <Button
                    className="button-85 "
                    type="submit"
                    colorScheme="purple"
                    width="full"
                    isLoading={isLoading}
                  >
                    Submit
                  </Button>
                </VStack>
              </form>
            </Box>
          </VStack>
        </ChakraProvider>
      </div>
    </>
  );
}

export default ContactUs;
