/**

* By Sam Ayoub info@ddkits.com

* Copyright: IST

* This component to auto build meta tags for reactJS

*/

import React, { useEffect } from 'react';
import { userStore } from './userStore';
import { Promise } from 'bluebird';

const metaTags = (props) => {
  const metaTagsList = [
    { name: 'title', content: props.title },

    { name: 'description', content: props.description.substring(0, 250) },

    { name: 'keywords', content: props.keywords },

    { name: 'author', content: props.author },

    { name: 'og:locale', content: userStore.getState().language },

    { name: 'og:title', content: props.title },

    { name: 'og:image', content: props.image },

    { name: 'og:url', content: window.location.href },

    { name: 'og:type', content: props.type },

    { name: 'og:sitename', content: props.sitename },

    { name: 'og:description', content: props.description.substring(0, 250) },

    { name: 'twitter:title', content: props.title },

    { name: 'twitter:image', content: props.image },

    { name: 'twitter:url', content: window.location.href },

    { name: 'twitter:card', content: props.description.substring(0, 250) },
  ];

  // Inject Meta Tags

  return new Promise((resolve) => {
    // Add title first as #1
    var title = document.querySelector('title');
    title.innerHTML = `${props.sitename}| ${props.title}`;
    var canonical = document.querySelector('link[rel="canonical"]');
    canonical.setAttribute('href', window.location.href);
    // Now we sync out meta tags

    metaTagsList.map((m) => {
      var meta = document.querySelector('meta[name="' + m.name + '"]');
      if (meta) {
        meta.setAttribute('content', m.content);
      } else {
        var newmeta = document.createElement('meta');
        newmeta.name = m.name;
        newmeta.content = m.content;
        document.head.appendChild(newmeta);
      }
    });
    resolve();
  });
};

export default metaTags;
