import styles from './styles.module.css';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { translator } from '../../languages/translator';
import { userStore } from '../../userStore';
import Loading from '../../Loading';
import { SocketContext } from '../../socket/Wsocket';

const RoomAndUsers = ({ username, room }) => {
  const socket = useContext(SocketContext);
  const [roomUsers, setRoomUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const user_id = userStore((s) => s.userId);
  const navigate = useNavigate();

  useEffect(() => {
    socket?.on('chatroom_users', (data) => {
      setRoomUsers(data);
    });
    setLoading(false);

    // return () => socket?.off('chatroom_users');
  }, [socket]);

  const leaveRoom = () => {
    const created_at = Date.now();
    if (room !== '' && user_id > 0) {
      socket?.emit('leave_room', { username, user_id, room, created_at });
    }
    // Redirect to home page
    navigate('/chat', { replace: true });
  };

  return (
    <div className={styles.roomAndUsersColumn}>
      <h2 className={styles.roomTitle}>{translator(room)}</h2>

      {loading ? (
        <Loading />
      ) : (
        <div>
          {roomUsers.length > 0 && (
            <>
              <h5 className={styles.usersTitle}>{translator('Active users')}</h5>

              <ul className={styles.usersList}>
                {roomUsers.map((user) => (
                  <li
                    style={{
                      fontWeight: `${user.username === username ? 'bold' : 'normal'}`,
                    }}
                    key={user.id}
                  >
                    {user.username}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}

      <button className="button-85" onClick={leaveRoom}>
        {translator('Leave')}
      </button>
    </div>
  );
};

export default RoomAndUsers;
