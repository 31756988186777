import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import useSubmit from '../services/useSubmit';
import { ChakraProvider } from '@chakra-ui/react';
import './Sell.css';
import axios from 'axios';
import { addItem, checkUser, userStore } from '../../core/userStore';
import { Link, Navigate } from 'react-router-dom';
import { ICountry, IState, ICity } from 'country-state-city';
import { toast } from 'react-toastify';
import { Toast } from 'bootstrap';
import fileDefault from './no-pic.png';
import { translator } from '../../core/languages/translator';
import { countries, countryCities, currencies } from '../../core/countries';
import Loading from '../../core/Loading';
import { bookCategories, checkText } from '../../core/Helpers';

export default function Sell(props) {
  const [mounted, setMounted] = useState(false);
  const [name, setname] = useState('');
  const [prev, setPrev] = useState('');
  const [files, setFiles] = useState([]);
  const [description, setdescription] = useState('');
  const [picture, setpicture] = useState('');
  const [location, setlocation] = useState('');
  const userId = userStore((state) => state.userId);
  const { isLoading, response, submit } = useSubmit();
  const [onOpen, setOnOpen] = useState([]);
  const [currency, setCurrency] = useState('');
  const [category, setCategory] = useState('');
  const [country, setCountry] = useState('');
  const isLoggedin = userStore((state) => state.loggedIn);
  useEffect(() => {
    setMounted(mounted);
  }, []);
  const submitFile = () => {
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('user_id', userId);
    return axios.post(`${process.env.REACT_APP_AUTH}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
  const formik = useFormik({
    initialValues: {
      title: '',
      price: 0,
      currency: [],
      condition: '',
      city: '',
      country: [],
      description: '',
      file: [],
      category: [],
    },
    onSubmit: (values) => {
      let finalValues = values;
      finalValues['picture'] = '';
      finalValues['user_id'] = userId;
      finalValues['country'] = country;
      finalValues['category'] = category;
      finalValues['currency'] = currency;
      finalValues['status'] = 1;
      finalValues['name'] = checkText(values['title']);
      finalValues['location'] = `${values['city']}, ${country}`;
      finalValues['description'] = checkText(values['description']);
      setOnOpen({ message: translator('Loading') });
      userStore.setState({ country: country, currency: currency, city: values['city'] });
      values?.file
        ? submitFile(values?.file)
            .then((response) => {
              setpicture(response.data.path);
              finalValues['picture'] = response.data.path;
            })
            .then((x) => {
              return submit('addnewitem', finalValues);
            })
            .catch((error) => {
              toast(JSON.stringify(error?.message || error));
            })
        : submit('addnewitem', finalValues);
    },
    validationSchema: Yup.object({
      title: Yup.string().required(translator('Required')),
      city: Yup.string().required(translator('Required')),
      price: Yup.number()
        .min(1, translator('Price should be more than 1'))
        .required(translator('Required')),
      description: Yup.string().required(translator('Required')),
      file: Yup.mixed().required(translator('File is required')),
    }),
  });

  useEffect(() => {
    if (response) {
      setOnOpen({ type: response.type, message: response.message });
      if (response.type === 'success') {
        formik.resetForm();
        window.location.href = '/profile';
      }
    }
  }, [response]);
  const handleFileUpload = (e, file = false) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setPrev(fileReader.result);
      }
    };
    setFiles(file ? [{ file }] : e.target.files);

    fileReader.readAsDataURL(file ? file : e.target.files[0]);
  };

  return userId !== 0 ? (
    isLoading ? (
      <Loading width="100%" />
    ) : (
      <>
        <div
          className="container p-5"
          style={{
            // eslint-disable-next-line react/prop-types
            backgroundColor: props.theme === 'dark' ? 'black' : 'white',
            // eslint-disable-next-line react/prop-types
            color: props.theme === 'dark' ? 'white' : 'black',
          }}
        >
          <ChakraProvider>
            <VStack alignItems="flex-start">
              <Heading as="h1" id="contactus">
                {translator('Sell Item')}
              </Heading>

              <Box p={6} rounded="md" w="100%">
                {onOpen?.message}
                <form onSubmit={formik.handleSubmit}>
                  <VStack spacing={4}>
                    {prev !== '' && <img width={'30%'} height={'30%'} src={prev} />}
                    <FormControl isInvalid={!!formik.errors.file && formik.touched.file}>
                      <FormLabel htmlFor="file"> {translator('Photo')}</FormLabel>
                      <Input
                        id="file"
                        name="file"
                        type="file"
                        onChange={(e) => {
                          handleFileUpload(e);
                        }}
                      />
                      <FormErrorMessage>{formik.errors.file}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.title && formik.touched.title}>
                      <FormLabel htmlFor="title"> {translator('Name')}</FormLabel>
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        {...formik.getFieldProps('title')}
                      />
                      <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.category && formik.touched.category}>
                      <FormLabel htmlFor="category"> {translator('Category')}</FormLabel>
                      <select
                        id="category"
                        icon={<></>}
                        required
                        value={category || ''}
                        // {...formik.getFieldProps('country')}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option key={Math.random()} value={''}>
                          {`${translator(`Select`)} ${translator(`Category`)}`}
                        </option>
                        {bookCategories.map((x) => {
                          return (
                            <option
                              key={x + Math.random()}
                              value={x}
                              {...(category === x ? 'selected' : '')}
                            >
                              {translator(x)}
                            </option>
                          );
                        })}
                      </select>
                      <FormErrorMessage>{formik.errors.category}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.condition && formik.touched.condition}>
                      <FormLabel htmlFor="condition"> {translator('Condition')}</FormLabel>
                      <Input
                        id="condition"
                        name="condition"
                        type="text"
                        {...formik.getFieldProps('condition')}
                      />
                      <FormErrorMessage>{formik.errors.condition}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!formik.errors.price && formik.touched.price}>
                      <FormLabel htmlFor="price"> {translator('Price')}</FormLabel>
                      <Input
                        id="price"
                        name="price"
                        type="number"
                        {...formik.getFieldProps('price')}
                      />
                      <FormErrorMessage>{formik.errors.price}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.currency && formik.touched.currency}>
                      <FormLabel htmlFor="currency">{translator('Currency')}</FormLabel>
                      <select
                        id="currency"
                        icon={<></>}
                        required
                        value={currency || ''}
                        // {...formik.getFieldProps('country')}
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option key={Math.random()} value={''}>
                          {`${translator(`Select`)} ${translator(`Currency`)}`}
                        </option>
                        {Object.keys(currencies).map((x) => {
                          return (
                            <option
                              key={currencies[x].name}
                              value={currencies[x].name}
                              {...(currency === x ? 'selected' : '')}
                            >
                              {currencies[x].symbol} - {currencies[x].name}
                            </option>
                          );
                        })}
                      </select>
                      <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.country && formik.touched.country}>
                      <FormLabel htmlFor="country">{translator('Country')}</FormLabel>
                      <select
                        id="country"
                        name="country"
                        required
                        value={country || ''}
                        // {...formik.getFieldProps('country')}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option key={Math.random()} value={''}>
                          {`${translator(`Select`)} ${translator(`Country`)}`}
                        </option>
                        {countries.map((x) => (
                          <option key={x.label + x.value} value={x.label}>
                            {translator(x.label)}
                          </option>
                        ))}
                      </select>
                      <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.city && formik.touched.city}>
                      <FormLabel htmlFor="city">{translator('City')}</FormLabel>
                      <Input
                        id="city"
                        type="text"
                        name="city"
                        {...formik.getFieldProps('city')}
                      ></Input>
                      <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={!!formik.errors.description && formik.touched.description}
                    >
                      <FormLabel htmlFor="description">{translator('Description')}</FormLabel>
                      <Textarea
                        id="description"
                        name="description"
                        type="textarea"
                        {...formik.getFieldProps('description')}
                      />
                      <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                    </FormControl>

                    <Button
                      className="button-85 "
                      type="submit"
                      colorScheme="purple"
                      width="full"
                      isLoading={isLoading}
                    >
                      {translator('Submit')}
                    </Button>
                  </VStack>
                </form>
              </Box>
            </VStack>
          </ChakraProvider>
        </div>
      </>
    )
  ) : (
    <Navigate to={'/account'} />
  );
}
