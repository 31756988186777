import { translator } from '../../../languages/translator';
import RoomAndUsers from '../room-and-users';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom'; // Add this
import { bookCategories, checkText } from '../../../Helpers';
import { useContext, useState } from 'react';
import { userStore } from '../../../userStore';
import { SocketContext } from '../../../socket/Wsocket';
//   const socket = useContext(SocketContext);

const ChatHome = ({ username, setUsername, room, setRoom }) => {
  const socket = useContext(SocketContext);

  const navigate = useNavigate(); // Add this
  const [category, setCategory] = useState('');
  const joinRoom = () => {
    // Redirect to /chat
    navigate(`/chat-room?room=${room}`, { replace: true });
  };
  return (
    <section id="chat-home">
      <div>
        <div className={styles.formContainer}>
          <h1>{`${translator('Kitab4Less')} | ${translator('Chat')}`}</h1>
          <p>{translator('Why to chat?')}</p>

          <table className="row ">
            <tbody>
              <td className="col-4 justify-center text-center align-middle">
                <h1>{translator('???')}</h1>
              </td>
              <td className="col-8 text-center ">
                <p>{translator('any question')}</p>
                <p>{translator('any request')}</p>
                <p>{translator('looking for a book')}</p>
                <p>{translator('having a unique book')}</p>
                <p>{translator('checking on book')}</p>
                <p>{translator('you are at the right place')}</p>
                <p>{translator('send a public message')}</p>
                <p>{translator('get your answer')}</p>
              </td>
            </tbody>
          </table>
          <p>{translator('good luck in your search')}</p>

          <select
            id="selectRoom"
            className={styles.input}
            onChange={(e) => {
              setCategory(e.target.value);
              setRoom(e.target.value);
            }} // Add this
            required
            value={category || ''}
          >
            <option key={Math.random() * Math.random()} value={``}>
              {`${translator(`Select`)} ${translator(`Category`)}`}
            </option>
            {bookCategories.length > 0 &&
              bookCategories.map((x, xds) => {
                return (
                  <option key={xds + Math.random()} value={x} name={x}>
                    {translator(x)}
                  </option>
                );
              })}
          </select>

          <button
            className="btn btn-secondary"
            style={{ width: '100%' }}
            onClick={joinRoom} // Add this
          >
            {translator('Join Room')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ChatHome;
