import React from 'react';
import { translator } from './languages/translator';
import { userStore } from './userStore';

export default function AboutUs(props) {
  const language = userStore((state) => state.language);
  const english = `<p><p>Welcome to Kitab 4 less!</p>
  <p>&nbsp;Your Premier Destination for Pre-Owned Books! At Kitab 4 less, we are passionate about connecting book enthusiasts with a treasure trove of pre-owned books.</p>
  <p><br></p>
  <p>&nbsp;Our online platform is dedicated to providing a convenient and sustainable way to get buyers and sellers of used books together through a simple yet a powerful platform, creating a mutual benefit for both sellers and buyers. By choosing Kitab 4 less, you actively participate in the eco-friendly practice of recycling books. Extend the life cycle of books, reduce waste, and contribute to a sustainable future. Each purchase supports the idea of giving books a second chance.&nbsp;</p>
  <p>We believe that every book deserves a second chance and we are here to help you find yours!</p></p>`;

  const arabic = `<p><p>مرحبًا بك في كتاب بسعر أقل! وجهتك الأولى للكتب المستعملة!</p>
  <p><br></p>
  <p>في كتاب بسعر أقل، نحن متحمسون لربط عشاق الكتب بكنز من الكتب المستعملة. منصتنا الإلكترونية مكرسة لتوفير طريقة مريحة ومستدامة لجمع المشترين والبائعين للكتب المستعملة من خلال منصة بسيطة وقوية في آن واحد، مما يخلق فائدة متبادلة للبائعين والمشترين. باختيارك لكتاب بسعر أقل، فإنك تشارك فعليًا في الممارسة الصديقة للبيئة لإعادة تدوير الكتب. استمرارية دورة حياة الكتب، وتقليل النفايات، والمساهمة في مستقبل مستدام. كل عملية شراء تدعم فكرة إعطاء الكتب فرصة ثانية.</p>
  <p><br></p>
  <p>نحن نؤمن بأن كل كتاب يستحق فرصة ثانية ونحن هنا لمساعدتك في إيجاد فرصتك!</p></p>`;
  return (
    <section className="container">
      <div dangerouslySetInnerHTML={{ __html: language === 'ar' ? arabic : english }} />
    </section>
  );
}
