import Loading from '../../Loading';
import { translator } from '../../languages/translator';
import { SocketContext } from '../../socket/Wsocket';
import { userStore } from '../../userStore';
import styles from './styles.module.css';
import { useState, useEffect, useRef, useContext } from 'react';

const RecievedMessages = ({ username, room }) => {
  const socket = useContext(SocketContext);
  const [messagesRecieved, setMessagesReceived] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const messagesColumnRef = useRef(null); // Add this
  const user_id = userStore((s) => s.userId);
  // Runs whenever a socket event is recieved from the server
  useEffect(() => {
    if (user_id > 0) {
      socket?.on('receive_message', (data) => {
        console.log(data.created_at);
        setMessagesReceived((state) => [
          ...state,
          {
            message: data.message,
            username: data.username,
            user_id: data.user_id,
            created_at: data.created_at,
          },
        ]);
      });

      // Remove event listener on component unmount
      return () => socket?.off('receive_message');
    }
  }, [socket]);
  useEffect(() => {
    if (user_id > 0) {
      setLoading(true);
      // Last 100 messages sent in the chat room (fetched from the db in backend)
      socket?.on('last_99_messages', (last99Messages) => {
        // Sort these messages by created_at
        last99Messages = sortMessagesByDate(last99Messages);
        setMessagesReceived((state) => [...last99Messages, ...state]);
        setLoading(false);
      });
      // Remove event listener on component unmount
      return () => socket?.off('last_99_messages');
    }
  }, [socket]);
  // Add this
  // Scroll to the most recent message
  useEffect(() => {
    setMounted(true);

    if (!loading) {
      messagesColumnRef.current.scrollTop = messagesColumnRef.current.scrollHeight;
    }
  }, [messagesRecieved]);

  // Add this
  function sortMessagesByDate(messages) {
    return messages.sort((a, b) => parseInt(a.created_at) - parseInt(b.created_at));
  }

  // dd/mm/yyyy, hh:mm:ss
  function formatDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString();
  }

  return loading ? (
    <Loading />
  ) : (
    // Add ref to this div
    <div className={styles.messagesColumn} ref={messagesColumnRef}>
      {messagesRecieved.map((msg, i) => (
        <div className={msg.username !== username ? styles.message : styles.mymessage} key={i}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={styles.msgMeta}>
              {msg.username === username ? translator('You') : msg.username}
            </span>
            <span className={styles.msgMeta}>{formatDateFromTimestamp(msg.created_at)}</span>
          </div>
          <p className={styles.msgText}>{msg.message}</p>
          <br />
        </div>
      ))}
    </div>
  );
};

export default RecievedMessages;
