import axios from 'axios';
import { EncodeNow, userStore } from '../../core/userStore';
import { messagesUpdate, socket } from '../../core/socket/Wsocket';

const API_URL = `${process.env.REACT_APP_AUTH}/`;

class AuthService {
  login(data) {
    return axios.post(API_URL + 'auth/login', data).then((response) => {
      if (response?.data?.token) {
        const values = {
          token: response?.data?.token || '',
          email: response?.data?.email || '',
          userId: response?.data?.user_id || 0,
          city: response?.data?.city || '',
          country: response?.data?.country || '',
          loggedIn: true,
          status: response?.data?.status,
          redirect: true,
          sessionId: response.data?.token || '',
          name: response.data?.name || `${response?.data?.firstname} ${response?.data?.lastname}`,
          senderName:
            response.data?.name || `${response?.data?.firstname} ${response?.data?.lastname}`,
          firstname: response?.data?.firstname || '',
          lastname: response?.data?.lastname || '',
          image: response?.data?.image || '',
          roles: response?.data?.roles || ['user'],
        };
        let encoded = EncodeNow(JSON.stringify(values));
        sessionStorage.setItem('data', encoded);
        localStorage.setItem('data', encoded);
        userStore.setState(values);

        return response.data;
      } else {
        return false;
      }
    });
  }

  logout() {
    localStorage.removeItem('data');
    socket.disconnect();
  }

  register(data) {
    return axios.post(API_URL + 'auth/signup', data);
  }
}
export default new AuthService();
