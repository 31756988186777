/* eslint-disable react/no-unknown-property */
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useSubmit from '../services/useSubmit';
import { ChakraProvider } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { userStore } from '../../core/userStore';
import { Navigate } from 'react-router-dom';
import { translator } from '../../core/languages/translator';
import { countries, countryCities } from '../../core/countries';
import { checkText } from '../../core/Helpers';
import Loading from '../../core/Loading';

export default function Register(props) {
  const { isLoading, response, submit } = useSubmit();
  const [onOpen, setOnOpen] = useState([]);
  const userId = userStore((state) => state.userId);
  const [mounted, setMounted] = useState(false);
  const [country, setCountry] = useState('');
  const [areacode, setAreacode] = useState('');
  const [city, setCity] = useState('');
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      country: countries,
      city: countryCities[country],
      password: '',
      passwordc: '',
    },
    onSubmit: (values) => {
      setCity(values['city']);
      values['country'] = country;
      values['city'] = checkText(values['city']);
      values['firstname'] = checkText(values['firstname']);
      values['lastname'] = checkText(values['lastname']);
      setOnOpen({ message: 'Loading' });
      submit('register', values);
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(translator('Required')),
      lastname: Yup.string().required(translator('Required')),
      email: Yup.string()
        .email(translator('Invalid email address'))
        .required(translator('Required')),
      phone: Yup.string()
        .min(8, translator('Must be at least 8 characters'))
        .required(translator('Required')),
      password: Yup.string()
        .min(6, translator('Must be at least 6 characters'))
        .required(translator('Required')),
      passwordc: Yup.string().oneOf(
        [Yup.ref('password'), null],
        translator('Passwords must match')
      ),
    }),
  });

  useEffect(() => {
    setMounted(true);
    if (response) {
      setOnOpen({ type: response.type, message: response.message });
      if (response.type === 'success') {
        formik.resetForm();
        // Redirect to home page
        navigate('/login', { replace: true });
      }
    }
  }, [response]);
  if (!mounted) {
    return <></>;
  }
  if (userId !== 0) {
    return <Navigate to={'/account'} />;
  }

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div
        className="container p-5"
        style={{
          // eslint-disable-next-line react/prop-types
          backgroundColor: props.theme === 'dark' ? 'black' : 'white',
          // eslint-disable-next-line react/prop-types
          color: props.theme === 'dark' ? 'white' : 'black',
        }}
      >
        <ChakraProvider>
          <VStack alignItems="flex-start">
            <Heading as="h1" id="contactus">
              {translator('Register')}
            </Heading>
            <Box p={6} rounded="md" w="100%">
              {onOpen?.message}
              <form onSubmit={formik.handleSubmit}>
                <VStack spacing={4}>
                  <FormControl isInvalid={!!formik.errors.firstname && formik.touched.firstname}>
                    <FormLabel htmlFor="firstname">{translator('First Name')}</FormLabel>
                    <Input id="firstname" name="firstname" {...formik.getFieldProps('firstname')} />
                    <FormErrorMessage>{formik.errors.firstname}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.lastname && formik.touched.lastname}>
                    <FormLabel htmlFor="lastname">{translator('Last Name')}</FormLabel>
                    <Input id="lastname" name="lastname" {...formik.getFieldProps('lastname')} />
                    <FormErrorMessage>{formik.errors.lastname}</FormErrorMessage>
                  </FormControl>
                  {/* email */}
                  <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                    <FormLabel htmlFor="email">{translator('Email Address')}</FormLabel>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      {...formik.getFieldProps('email')}
                    />
                    <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                  </FormControl>
                  {/* country */}
                  <FormControl isInvalid={!!formik.errors.country && formik.touched.country}>
                    <FormLabel htmlFor="country">{translator('Country')}</FormLabel>
                    <select
                      id="country"
                      name="country"
                      required
                      value={country || ''}
                      // {...formik.getFieldProps('country')}
                      onChange={(e) => {
                        setCountry(e.target.value);
                        // get area code
                        setAreacode(countries.filter((x) => x.label === e.target.value)[0].code);
                      }}
                    >
                      <option key={Math.random()} value={''}>
                        {`${translator(`Select`)} ${translator(`Country`)}`}
                      </option>
                      {countries.map((x) => (
                        <option key={x.label + x.value} value={x.label}>
                          {translator(x.label)}
                        </option>
                      ))}
                    </select>
                    <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!formik.errors.city && formik.touched.city}>
                    <FormLabel htmlFor="city">
                      {country} {translator('City')}
                    </FormLabel>
                    <Input
                      id="city"
                      type="text"
                      name="city"
                      {...formik.getFieldProps('city')}
                    ></Input>
                    <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                  </FormControl>
                  {/* phone */}
                  <FormControl isInvalid={!!formik.errors.phone && formik.touched.phone}>
                    <FormLabel htmlFor="phone">{translator('Phone')}</FormLabel>
                    <section className="d-flex" style={{ direction: 'ltr' }}>
                      <div className="p-absolute bg-dark text-light border">{areacode}</div>
                      <Input
                        id="phone"
                        name="phone"
                        type="phone"
                        {...formik.getFieldProps('phone')}
                      />
                    </section>
                    <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!formik.errors.password && formik.touched.password}>
                    <FormLabel htmlFor="password">{translator('Password')}</FormLabel>
                    <Input
                      id="password"
                      name="password"
                      type="password"
                      {...formik.getFieldProps('password')}
                    />
                    <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.passwordc && formik.touched.passwordc}>
                    <FormLabel htmlFor="passwordc">{translator('Password Confirmation')}</FormLabel>
                    <Input
                      id="passwordc"
                      name="passwordc"
                      type="password"
                      {...formik.getFieldProps('passwordc')}
                    />
                    <FormErrorMessage>{formik.errors.passwordc}</FormErrorMessage>
                  </FormControl>

                  <Button
                    type="submit"
                    className="button-85 "
                    colorScheme="purple"
                    width="full"
                    isLoading={isLoading}
                  >
                    {translator('Submit')}
                  </Button>
                </VStack>
              </form>
            </Box>
          </VStack>
        </ChakraProvider>
      </div>
    </>
  );
}
