import React, { Component, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';
import './Account.css';
import AuthService from '../services/index';
import { changepic, checkUser, getMyItems, syncCache, userStore } from '../../core/userStore';
import { toast } from 'react-toastify';
import Card from '../assets/card';
import { translator } from '../../core/languages/translator';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../core/Loading';

export default function Account(props) {
  const [page, setPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState([]);
  const [file, setFile] = useState('');
  const profilePic = userStore((state) => state.image);
  const setprofilePic = (data) => userStore.setState({ image: data });
  const isLoggedIn = userStore((state) => state.loggedIn);
  const myName = userStore((state) => state.senderName);
  const userId = userStore((state) => state.userId);
  const email = userStore((state) => state.email);

  useEffect(() => {
    setMounted(true);
    getMyItems(userId, page, 10)
      .then((result) => {
        setPost(result);
        setLoading(false);
      })
      .catch((err) => {
        toast(err?.message);
        setLoading(false);
      });
  }, []);

  const fetchMoreData = async () => {
    getMyItems(userId, page + 1, 10).then((data) => {
      setPage(page + 1);
      setLoading(true);
      setPost(post.concat(data));
      setLoading(false);
      console.clear();
    });
  };
  const submitFile = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('file', file[0]);
    axios
      .post(`${process.env.REACT_APP_AUTH}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        const image = response?.data?.path;
        if (image) {
          setprofilePic(image);
          changepic(image, userId).then((x) => {
            syncCache(userStore.getState());
            toast(translator('Success'));
          });
        } else {
          toast(translator('Try Again'));
        }
      })
      .catch((error) => {
        // handle your error
        console.log(error);
      });
  };

  const handleFileUpload = (event) => {
    setFile(event.target.files);
  };

  if (!mounted) {
    return <></>;
  }
  return userId !== 0 ? (
    <>
      <div className=" m-0">
        <div className="col-md-12 m-0">
          {profilePic && profilePic !== '' ? (
            <>
              <img
                className="profile-pic d-block  col-3"
                src={`${process.env.REACT_APP_AUTH}/files/${profilePic}`}
                alt={myName}
              />
            </>
          ) : (
            <>
              <img
                className="profile-pic d-block col-3"
                src={`/assets/img/avatar.png`}
                alt={myName}
              />
            </>
          )}
          <h1>{myName}</h1>
          <form className=" " onSubmit={submitFile}>
            <label className="myLabel">
              <input
                className="file-icom"
                label="upload file"
                type="file"
                onChange={handleFileUpload}
              />
              <span>{translator('Select Picture')}</span>
            </label>
            <button className="button-85 sell-btn" type="submit">
              {translator('upload')}
            </button>
          </form>

          <br></br>
        </div>
        <div className="col-md-12  m-0 ">
          <div className="container ">
            <h1 className="p-2">{translator('My Items')}</h1>
            <Link to={'/sell'} className="btn btn-success m-2 p-2">
              {translator('Add New Item')}
            </Link>
            <Link to={'/messages'} className="btn btn-success m-2 p-2">
              {translator('Messages')}
            </Link>
            <Link to={'/myoffers'} className="btn btn-success m-2 p-2">
              {translator('My Offers')}
            </Link>
            <div className="container my-3">
              {loading && <Loading width="100%" />}
              <InfiniteScroll
                dataLength={post?.length}
                next={fetchMoreData}
                hasMore={post?.length !== totalResults}
                loader={loading && <Loading width="50%" />}
              >
                <div className="container">
                  <div className="row">
                    {post.length
                      ? post.map((item, index) => {
                          return (
                            <div key={item.item_id} className="col-md-3">
                              <Card
                                myprofile={true}
                                key={index * Math.random()}
                                item={item}
                                index={index}
                              />
                            </div>
                          );
                        })
                      : []}
                  </div>
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      {userId > 0 ? (
        translator('No Items to sell yet')
      ) : (
        <>
          {' '}
          {translator('Unauthorized, please')}{' '}
          <Link to={'/login'}> {translator('click here to login')}</Link>
        </>
      )}
    </>
  );
}

// export default connect(mapStateToProps, { checkUser, changepic })(Account);
