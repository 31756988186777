import React from 'react';
import logo from './assets/logo.png';
import { translator } from './languages/translator';
import { Link } from 'react-router-dom';

export default function Footer() {
  const copyRight = translator('Copyright') + '@ ' + translator('Kitab4less');
  return (
    <footer className="row col align-center">
      <div className="col-4">
        <img src={logo} width="50" height="50" aria-describedby={copyRight} alt={copyRight} />
      </div>
      <div className="col-4">
        <p>
          {copyRight}, <Link to={'/policy-and-terms'}>Policy and terms</Link>
        </p>
      </div>
    </footer>
  );
}
