import React, { Component, useEffect, useState } from 'react';
import './Header.css';
import logo from './logo.png';
import { Link } from 'react-router-dom';
import { logout, userStore } from '../../core/userStore';
import MenuNav from './navs/MenuNav';

export default function Header(props) {
  return <MenuNav theme={props?.theme || 'dark'} />;
  //   return (
  //     <nav className="navbar  sticky-top  navbar-expand navbar-dark bg-dark justify-space-between">
  //       <div className="logo">
  //         <Link to="/">
  //           <img className="animated  rollIn" src={logo} alt="logo"></img>
  //           kitab4less
  //         </Link>
  //       </div>
  //       <form className="search-form center">
  //         {/* <input type="text" placeholder="  Search..." className="search" onChange={handleSearch}></input> */}
  //         <input type="text" className="search" onChange={handleSearch}></input>
  //         <Link to={`/search?q=${search}`}>
  //           <button className="search-btn" type="submit">
  //             Search
  //           </button>
  //         </Link>
  //       </form>

  //       <ul className="navbar-nav mr-auto">
  //         <li className="nav-item">
  //           <Link to={'/'} className="nav-link">
  //             Home
  //           </Link>
  //         </li>

  //         {showModeratorBoard && (
  //           <li className="nav-item">
  //             <Link to={'/mod'} className="nav-link">
  //               Moderator Board
  //             </Link>
  //           </li>
  //         )}

  //         {showAdminBoard && (
  //           <li className="nav-item">
  //             <Link to={'/admin'} className="nav-link">
  //               Admin Board
  //             </Link>
  //           </li>
  //         )}

  //         {userId !== 0 && (
  //           <>
  //             <li className="nav-item">
  //               <Link to={'/sell'} className="nav-link">
  //                 Sell
  //               </Link>
  //             </li>
  //             <li className="nav-item">
  //               <Link to={'/account'} className="nav-link">
  //                 My Account
  //               </Link>
  //             </li>
  //           </>
  //         )}

  //         {userId !== 0 ? (
  //           <div className="navbar-nav ml-auto">
  //             <li className="nav-item">
  //               <Link to={'/profile'} className="nav-link">
  //                 {currentUser.firstname}
  //               </Link>
  //             </li>
  //             <li className="nav-item">
  //               <a href="/login" className="nav-link" onClick={() => props?.logoutCallback()}>
  //                 LogOut
  //               </a>
  //             </li>
  //           </div>
  //         ) : (
  //           <div className="navbar-nav ml-auto">
  //             <li className="nav-item">
  //               <Link to={'/login'} className="nav-link">
  //                 Login
  //               </Link>
  //             </li>

  //             <li className="nav-item">
  //               <Link to={'/register'} className="nav-link">
  //                 Register
  //               </Link>
  //             </li>
  //           </div>
  //         )}
  //       </ul>
  //     </nav>
  //   );
}
