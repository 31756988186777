/* eslint-disable no-undef */
import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import './Item.css';
import {
  create_slug,
  getItems,
  getOneItem,
  getSeller,
  soldPng,
  userStore,
} from '../../core/userStore';

import { Link, Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../assets/card';
import Loading from '../../core/Loading';
import CustomModal from '../../core/CustomModal';
import Sell from '../sell/Sell';
import Message from '../message/Message';
import { translator } from '../../core/languages/translator';
import { checkText } from '../../core/Helpers';
import metaTags from '../../core/MetaTags';
import useSubmit from '../services/useSubmit';
import ConfirmationButton from '../../core/ConfirmationButton';

toast.configure();

export default function Item(props) {
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [item, setitem] = useState([]);
  const [seller, setseller] = useState([]);
  const [products, setproducts] = useState([]);
  const [ownerProducts, setOwnerproducts] = useState([]);
  const params = useParams();
  const userId = userStore((state) => state.userId);
  const [showMenu, setshowMenu] = useState(false);
  const { isLoading, response, submit } = useSubmit();
  const theme = userStore((s) => s.theme);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_AUTH}/delete/${id}`, { user_id: userId })
      .then((x) => {
        toast(translator('Deleted'));
        setLoading(false);
        setTimeout(() => {
          window.location.href = '/profile';
        }, 1000);
      })
      .catch((x) => {
        toast(translator('Something Wrong!'));
        setLoading(false);
        setTimeout(() => {
          window.location.href = '/profile';
        }, 1000);
      });
    setshowMenu(false);
  };
  const handleSold = (id, data) => {
    let results = data;
    results.status = 0;
    submit('updateItem', results)
      .then((x) => {
        toast(translator('Congrats a lot more to sell!'));
      })
      .catch((x) => toast(translator('Something Wrong!')));
    setshowMenu(false);
  };

  useEffect(() => {
    getOneItem(parseInt(params?.id)).then((data) => {
      setitem(data);
    });
  }, []);
  useEffect(() => {
    if (item[0]?.user_id && item[0]?.user_id > -1) {
      getSeller(parseInt(item[0]?.user_id)).then((data) => {
        setseller(data);
        getItems(0, 5, data?.user_id).then((data) => {
          setOwnerproducts(data);
        });
      });
    }
    getItems(0, 5).then((data) => {
      setproducts(data);
    });
  }, [item[0]]);
  const relatedItems = 4;
  const displaytoprated = () => {
    let count = 0;
    return (
      <>
        <br />
        <h3 className="mt-3">{translator(translator('Other items for sale'))}</h3>
        <br />
        {products?.length ? (
          products.map((item, index) => {
            if (count < relatedItems && item?.item_id !== parseInt(params?.id)) {
              count++;
              return (
                <div key={item?.name + index} className="col-md-3">
                  <Card nodate={true} item={item} index={index} />
                </div>
              );
            }
          })
        ) : (
          <Loading />
        )}
      </>
    );
  };
  const ownerProductsDisplay = () => {
    let count = 0;
    return (
      <>
        <br />
        <small>
          {translator('Other items for sale')} {seller?.firstname}
        </small>
        <br />
        {ownerProducts?.length ? (
          ownerProducts.map((item, index) => {
            if (count < relatedItems && item?.item_id !== parseInt(params?.id)) {
              count++;
              return (
                <div key={item?.name + index} className="col-md-12">
                  <Card nodate={true} onlyimage={true} item={item} index={index} />
                </div>
              );
            }
          })
        ) : (
          <Loading />
        )}
      </>
    );
  };
  useEffect(() => {
    {
      item[0] &&
        seller?.user_id &&
        metaTags({
          sitename: translator('Kitab4less'),
          title: item[0]?.name,
          author: `${seller?.firstname} ${seller?.lastname}`,
          description: checkText(item[0]?.description),
          keywords: `${item[0]?.name}, ${item[0]?.location}, ${seller?.firstname}, ${seller?.lastname}, ${item[0]?.currency} ,${item[0]?.price}`,
          image: `${process.env.REACT_APP_AUTH}/files/${item[0]?.picture}`,
        }).then(() => {
          setMounted(true);
          setLoading(false);
        });
    }
  }, [seller]);
  if (!mounted) {
    return <></>;
  }

  return loading || isLoading ? (
    <Loading width="100%" />
  ) : (
    <>
      <div className="col-md-12 row text-center">
        <section className="col-md-10">
          {userId === item[0]?.user_id && (
            <>
              <button
                className="nav-item p-2  pt-0"
                data-bs-toggle="modal"
                data-bs-target={`#${create_slug(
                  `itemCardEdit-${item[0]?.item_id}-${item[0]?.item_id}`
                )}`}
                href={create_slug(`itemCardEdit-${item[0]?.item_id}-${item[0]?.item_id}`)}
                role="button"
                onClick={() => setshowMenu(!showMenu)}
                style={{
                  // eslint-disable-next-line react/prop-types
                  backgroundColor: theme === 'dark' ? 'black' : 'white',
                  // eslint-disable-next-line react/prop-types
                  color: theme === 'dark' ? 'white' : 'black',
                  fontSize: 30,
                  width: '100%',
                }}
              >
                <span href="#" dangerouslySetInnerHTML={{ __html: '&#9998;' }}></span>
              </button>
            </>
          )}
          <div className="card-image-full">
            {item[0]?.status !== '1' ? <img id="sold" src={soldPng} /> : []}
            {item[0] ? (
              <img
                src={`${process.env.REACT_APP_AUTH}/files/${item[0]?.picture}`}
                alt={item[0]?.name}
                width={'100%'}
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_AUTH}/files/uploads/no-pic.png}`}
                alt={item[0]?.name}
                width={'100%'}
              />
            )}
          </div>
          {item[0] ? (
            <div className="container col-md-8">
              <p>{checkText(item[0]?.description)}</p>
              <p className="item-name">
                {checkText(item[0]?.name)} {item[0]?.status !== '1' ? ' SOLD ' : []}
              </p>
              <p className="price">
                {item[0]?.currency} {item[0]?.price}
              </p>
              <p> {item[0]?.location}</p>
              <p>
                {isNaN(item[0]?.created_at) == false ? (
                  <small>{new Date(Date.parse(item[0]?.created_at)).toString()}</small>
                ) : (
                  []
                )}
              </p>
              <p>
                <span className="badge sm-badge bg-dark">{item[0]?.category || ''}</span>
              </p>
            </div>
          ) : (
            <Loading width={'100%'} />
          )}
          {item[0]?.user_id !== userId && item[0]?.status === '1' ? (
            <section className="row">
              <div className="container">
                {/* <button
                  className="button-85 col-md-3"
                  data-bs-toggle="modal"
                  data-bs-target="#messageto"
                  href="#"
                  role="button"
                >
                  {translator('Send a message')}
                </button> */}
                <button
                  className="button-85 col-md-3"
                  data-bs-toggle="modal"
                  data-bs-target="#makeanoffer"
                  href="#"
                  role="button"
                >
                  {translator('Make An Offer')}
                </button>
                {/* <button
            className="button-85 col-3"
            data-bs-toggle="modal"
            data-bs-target="#messageto"
            href="#"
            role="button"
          >
            {translator('Rent')}
          </button> */}
              </div>
            </section>
          ) : (
            []
          )}
          <div className="row">{displaytoprated()}</div>
          {/* <CustomModal
            steps={false}
            title1={`${translator('Send a message')}`}
            classid={'messageto'}
            details1={
              <Message
                messageSentCallback={(x) => {
                  toast(translator(x?.message || 'Sent'));
                }}
                item_id={item[0]?.item_id}
                title={item[0]?.name}
                owner={item[0]?.user_id}
                price={item[0]?.price}
                currency={item[0]?.currency}
              />
            }
          /> */}
          <CustomModal
            steps={false}
            title1={`${translator('Make An Offer')}`}
            classid={'makeanoffer'}
            details1={
              <Message
                offer={true}
                messageSentCallback={() => {}}
                item_id={item[0]?.item_id}
                title={item[0]?.name}
                owner={item[0]?.user_id}
                price={item[0]?.price}
                currency={item[0]?.currency}
              />
            }
          />
        </section>
        <section className="col-md-2">
          {seller && seller?.firstname ? (
            <div className="col-md-12 text-center">
              {seller?.image ? (
                <img
                  height={'100px'}
                  width={'100px'}
                  src={`${process.env.REACT_APP_AUTH}/files/${seller?.image}`}
                  alt={`${seller?.firstname} ${seller?.lastname}`}
                />
              ) : (
                <img
                  height={'100px'}
                  width={'100px'}
                  src={`${process.env.REACT_APP_AUTH}/files/uploads/no-pic.png`}
                  alt={`${seller?.firstname} ${seller?.lastname}`}
                />
              )}
              <h3>{`${seller?.firstname} ${seller?.lastname}`}</h3>

              <div className="cards">{ownerProductsDisplay()}</div>
            </div>
          ) : (
            <></>
          )}
          <CustomModal
            status={showMenu}
            steps={false}
            title1={translator(item[0]?.name)}
            className={'text-black ' + !showMenu ? 'hide' : ''}
            classid={create_slug(`itemCardEdit-${item[0]?.item_id}-${item[0]?.item_id}`)}
            details1={
              <>
                {' '}
                <section className="row">
                  <div className="col-12">
                    <Link to={`/edit/${item[0]?.item_id}`}>
                      <button
                        style={{ width: '100%' }}
                        type="button"
                        data-bs-dismiss="modal"
                        className=" button-85 sell-btn mt-2"
                      >
                        {translator('Edit')}
                      </button>
                    </Link>
                  </div>
                  <div className="col-12">
                    <Link to="/account">
                      <button
                        style={{ width: '100%' }}
                        hidden={item[0]?.status !== '1' ? true : false}
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          handleSold(item[0]?.item_id, item);
                        }}
                        className=" button-85 sell-btn mt-2"
                      >
                        {translator('Sold')}
                      </button>
                    </Link>
                  </div>
                  <div className="col-12">
                    <ConfirmationButton
                      onConfirm={() => {
                        handleDelete(item[0]?.item_id);
                      }}
                      title={translator('Delete')}
                      query="Are you sure...?"
                    />
                  </div>
                </section>
              </>
            }
          />
        </section>
      </div>
    </>
  );
}
