import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './Message.css';
import { getMessages, userStore } from '../../core/userStore';
import Loading from '../../core/Loading';
import { Link, Navigate } from 'react-router-dom';
import { translator } from '../../core/languages/translator';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import useSubmit from '../services/useSubmit';
import { ChakraProvider } from '@chakra-ui/react';
import { countries, countryCities } from '../../core/countries';
import { messagesUpdate } from '../../core/socket/SocketFun';

export default function Message(props) {
  const [message, setMessage] = useState('');
  const { isLoading, response, submit } = useSubmit();
  const [onOpen, setOnOpen] = useState([]);
  const userId = userStore((state) => state.userId);
  const [mounted, setMounted] = useState(false);
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState('');
  const [item_id, setitem_id] = useState(0);
  const [title, settitle] = useState('');
  const [owner, setOwner] = useState(0);
  const senderName = `${userStore((state) => state.firstname)} ${userStore(
    (state) => state.lastname
  )}`;
  useEffect(() => {
    setPrice(props?.price);
    setCurrency(props?.currency);
    setitem_id(props?.item_id);
    settitle(props?.title);
    setOwner(props?.owner);
    setMounted(true);
  }, [props]);
  const formik = useFormik({
    initialValues: {
      message: '',
      price: props?.price,
    },
    onSubmit: (values) => {
      setOnOpen({ message: 'Loading' });
      let data = values;
      values['sender'] = userId;
      values['senderName'] = senderName;
      values['owner'] = owner;
      values['title'] = title;
      values['item_id'] = item_id;
      submit('sendmessage', data).then((x) => {
        messagesUpdate(userId, owner);
      });
    },
    validationSchema: Yup.object({
      message: Yup.string().required(translator('Required')),
      price: Yup.number().required(translator('Required')),
    }),
  });

  useEffect(() => {
    if (response) {
      setOnOpen({ type: response.type, message: response.message });
      if (response.type === 'success') {
        formik.resetForm();
      }
    }
  }, [response]);
  return mounted ? (
    <>
      <div
        className="container"
        style={{
          // eslint-disable-next-line react/prop-types
          backgroundColor: props.theme === 'dark' ? 'black' : 'white',
          // eslint-disable-next-line react/prop-types
          color: props.theme === 'dark' ? 'white' : 'black',
        }}
      >
        {userId > 0 && !isLoading ? (
          <ChakraProvider>
            <VStack alignItems="flex-start">
              <Heading as="h1" id="message">
                {translator(title)}
              </Heading>
              <Box p={6} rounded="md" w="100%">
                {onOpen?.message}
                <form onSubmit={formik.handleSubmit}>
                  <VStack spacing={4}>
                    <FormControl isInvalid={!!formik.errors.price && formik.touched.price}>
                      <FormLabel htmlFor="price">{translator('Price')}</FormLabel>
                      <Input
                        id="price"
                        name="price"
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        {...formik.getFieldProps('price')}
                      />
                      <FormErrorMessage>{formik.errors.price}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.message && formik.touched.message}>
                      <FormLabel htmlFor="message">{translator('Message')}</FormLabel>
                      <Textarea id="message" name="message" {...formik.getFieldProps('message')} />
                      <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                    </FormControl>

                    <Button
                      type="submit"
                      className="button-85 "
                      data-bs-dismiss="modal"
                      colorScheme="purple"
                      width="full"
                      isLoading={isLoading}
                    >
                      {translator('Submit')}
                    </Button>
                  </VStack>
                </form>
              </Box>
            </VStack>
          </ChakraProvider>
        ) : userId > 0 ? (
          []
        ) : (
          <a href="/login">{`${translator('Login')}`}</a>
        )}
      </div>
    </>
  ) : (
    <></>
  );
  // return loading ? (
  //   <Loading width={'100%'} />
  // ) : (
  //   <>
  //     {props?.direct ? (
  //       <form className="form-group d-flex" onSubmit={handleSubmit}>
  //         <div className="form-control border">
  //           <label htmlFor="price">{translator('Price')}</label>
  //           <input
  //             type="number"
  //             name="price"
  //             id="price"
  //             onChange={(e) => setPrice(e.target.value)}
  //             value={price}
  //           ></input>
  //           <textarea
  //             name="message"
  //             className="message-input"
  //             onChange={(e) => setMessage(e.target.value)}
  //             value={message}
  //           ></textarea>
  //           <button type="submit" className="sell-btn send">
  //             {translator('Send')}
  //           </button>
  //         </div>
  //       </form>
  //     ) : (
  //       <div className="container">
  //         <form className="Message-form" onSubmit={handleSubmit}>
  //           <span> {title}</span>
  //           <span>{translator('Price')}</span>
  //           <input
  //             type="number"
  //             name="price"
  //             onChange={(e) => setPrice(e.target.value)}
  //             value={price}
  //           />

  //           <span>{translator('Message')}</span>

  //           <textarea
  //             name="message"
  //             className="message-input"
  //             onChange={(e) => setMessage(e.target.value)}
  //             value={message}
  //           ></textarea>
  //           <button type="submit" className="sell-btn send">
  //             {translator('Send')}
  //           </button>
  //         </form>
  //       </div>
  //     )}
  //   </>
  // );
}
