/**
 * Built by Sam Ayoub <melayyoub@outlook.com> Reallexi.com
 * https://github.com/ddkits
 * https://revwgame.com
 * Important: To use this code please leave the copyright in place
 * Reallexi LLC, https://reallexi.com
 */
/* eslint-disable */
import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from './core/ErrorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
