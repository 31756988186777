const en = [
  'login',
  'register',
  'home',
  'kitab4less',
  'copyright',
  'email',
  'signin',
  'signup',
  'contact us',
  'failed',
  'password',
  'throttle',
  'user name',
  'previous',
  'next',
  'accepted',
  'accepted_if',
  'active_url',
  'after',
  'array',
  'before',
  'between',
  'sell',
  'boolean',
  'confirmed',
  'current_password',
  'date',
  'date_equals',
  'different',
  'ends_with',
  'exists',
  'file',
  'upload',
  'filled',
  'location',
  'country',
  'ip',
  'lowercase',
  'mimes',
  'not_in',
  'required',
  'same',
  'size',
  'string',
  'already',
  'unique',
  'uploaded',
  'url',
  'title',
  'reason',
  'logout',
  'homepage',
  'back',
  'forward',
  'search',
  'e-mail',
  'email',
  'mail',
  'city',
  'phone',
  'number',
  'test',
  'click',
  'use',
  'get',
  'post',
  'buy',
  'make',
  'offer',
  'username',
  'name',
  'first',
  'last',
  'confirmation',
  'city',
  'country',
  'address',
  'phone',
  'mobile',
  'age',
  'sex',
  'gender',
  'day',
  'month',
  'year',
  'hour',
  'minute',
  'second',
  'content',
  'description',
  'excerpt',
  'date',
  'time',
  'available',
  'size',
  'price',
  'desc',
  'title',
  'q',
  'link',
  'slug',
  'kitab4less',
  'message',
  'rent',
  'make an offer',
  'message sent',
  'something wrong',
  'sent',
  'location',
  'send a message',
  'your message',
  'email address',
  'type of enquiry',
  'invalid email address',
  'must be at least 25 characters',
  'loading',
  'password confirmation',
  'first name',
  'last name',
  'submit',
  'delete',
  'edit',
  'user was registered successfully!',
  'my items',
  'upload',
  'uploaded',
  'select picture',
  'add new item',
  'no items to sell yet',
  'photo',
  'condition',
  'currency',
  'select',
  'no messages yet',
  'my messages',
  'other items for sale',
  'unauthorized, please',
  'click here to login',
  'click here',
  'policy and terms',
  // add below
  'sold',
  'english language and literature',
  'mathematics',
  'science (physics, chemistry, biology)',
  'social sciences (history, geography, civics)',
  'foreign languages (french, spanish, german, etc.)',
  'business studies/economics',
  'computer science/information technology',
  'arts and humanities',
  'environmental studies',
  'novels',
  'non-fiction books',
  'personal growth and self development',
  'business and entrepreneurship',
  'psychology',
  'philosophy',
  'travel',
  'cooking/food',
  'health/fitness',
  'education/family',
  'poetry',
  'religion/spirituality',
  'other',
  'category',
  // countries
  'afghanistan',
  'albania',
  'algeria',
  'andorra',
  'angola',
  'anguilla',
  'antigua &amp; barbuda',
  'argentina',
  'armenia',
  'aruba',
  'australia',
  'austria',
  'azerbaijan',
  'bahamas',
  'bahrain',
  'bangladesh',
  'barbados',
  'belarus',
  'belgium',
  'belize',
  'benin',
  'bermuda',
  'bhutan',
  'bolivia',
  'bosnia &amp; herzegovina',
  'botswana',
  'brazil',
  'british virgin islands',
  'brunei',
  'bulgaria',
  'burkina faso',
  'burundi',
  'cambodia',
  'cameroon',
  'cape verde',
  'cayman islands',
  'chad',
  'chile',
  'china',
  'colombia',
  'congo',
  'cook islands',
  'costa Rica',
  'cote d ivoire',
  'croatia',
  'cruise ship',
  'cuba',
  'cyprus',
  'czech Republic',
  'denmark',
  'djibouti',
  'dominica',
  'dominican Republic',
  'ecuador',
  'egypt',
  'el salvador',
  'equatorial guinea',
  'estonia',
  'ethiopia',
  'falkland islands',
  'faroe islands',
  'fiji',
  'finland',
  'france',
  'french polynesia',
  'french west indies',
  'gabon',
  'gambia',
  'georgia',
  'germany',
  'ghana',
  'gibraltar',
  'greece',
  'greenland',
  'grenada',
  'guam',
  'guatemala',
  'guernsey',
  'guinea',
  'guinea bissau',
  'guyana',
  'haiti',
  'honduras',
  'hong kong',
  'hungary',
  'iceland',
  'india',
  'indonesia',
  'iran',
  'iraq',
  'ireland',
  'isle of man',
  'israel',
  'italy',
  'jamaica',
  'japan',
  'jersey',
  'jordan',
  'kazakhstan',
  'kenya',
  'kuwait',
  'kyrgyz Republic',
  'laos',
  'latvia',
  'lebanon',
  'lesotho',
  'liberia',
  'libya',
  'liechtenstein',
  'lithuania',
  'luxembourg',
  'macau',
  'macedonia',
  'madagascar',
  'malawi',
  'malaysia',
  'maldives',
  'mali',
  'malta',
  'mauritania',
  'mauritius',
  'mexico',
  'moldova',
  'monaco',
  'mongolia',
  'montenegro',
  'montserrat',
  'morocco',
  'mozambique',
  'namibia',
  'nepal',
  'netherlands',
  'netherlands antilles',
  'new caledonia',
  'new zealand',
  'nicaragua',
  'niger',
  'nigeria',
  'norway',
  'oman',
  'pakistan',
  'palestine',
  'panama',
  'papua new guinea',
  'paraguay',
  'peru',
  'philippines',
  'poland',
  'portugal',
  'puerto Rico',
  'qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'saint pierre &amp; miquelon',
  'samoa',
  'san marino',
  'satellite',
  'saudi arabia',
  'senegal',
  'serbia',
  'seychelles',
  'sierra leone',
  'singapore',
  'slovakia',
  'slovenia',
  'south africa',
  'south korea',
  'spain',
  'sri lanka',
  'st kitts &amp; nevis',
  'st lucia',
  'st vincent',
  'st. lucia',
  'sudan',
  'suriname',
  'swaziland',
  'sweden',
  'switzerland',
  'syria',
  'taiwan',
  'tajikistan',
  'tanzania',
  'thailand',
  "timor l'este",
  'togo',
  'tonga',
  'trinidad &amp; tobago',
  'tunisia',
  'turkey',
  'turkmenistan',
  'turks &amp; caicos',
  'uganda',
  'ukraine',
  'united arab emirates',
  'united kingdom',
  'uruguay',
  'uzbekistan',
  'venezuela',
  'vietnam',
  'virgin islands (us)',
  'yemen',
  'zambia',
  'zimbabwe',
  'sell item',
  'general',
  'support',
  'direction',
  'need help',
  'message body',
  'inbox',
  'messages',
  'new',
  'used',
  'preowned',
  'not used',
  'my offers',
  'status',
  'pending',
  'rejected',
  'accepted',
  // from here
  'chat',
  'send',
  'active',
  'online',
  'active users',
  'offline',
  'leave',
  'leave chat',
  'write message',
  'you recieved a new message',
  'you',
  `my account`,
  // chat trans
  'why to chat?',
  'any question',
  'any request',
  'looking for a book',
  'having a unique book',
  'checking on book',
  'you are at the right place',
  'send a public message',
  'get your answer',
  'good luck in your search',
];

export { en };
