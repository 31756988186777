import React, { Component, useEffect, useState } from 'react';
import './Search.css';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Card from '../assets/card';
import { translator } from '../../core/languages/translator';
import Loading from '../../core/Loading';
import { searchNow } from '../../core/userStore';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Search(props) {
  const [mounted, setMounted] = useState(false);
  const [foundItems, setFounditems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    setMounted(mounted);
    searchNow(searchParams.get('q'), page, 10)
      .then((result) => {
        setFounditems(result);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, []);
  useEffect(() => {
    setLoading(true);
    setPage(0);
    searchNow(searchParams.get('q'), page, 10)
      .then((result) => {
        setFounditems(result);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [searchParams.get('q')]);
  const fetchMoreData = async () => {
    searchNow(searchParams.get('q'), page + 1, 10).then((data) => {
      setPage(page + 1);
      setLoading(true);
      setFounditems(foundItems.concat(data));
      setLoading(false);
      console.clear();
    });
  };

  return (
    <div className="container my-3">
      {loading && <Loading width="100%" />}
      <InfiniteScroll
        dataLength={foundItems?.length}
        next={fetchMoreData}
        hasMore={foundItems?.length !== totalResults}
        loader={loading && <Loading width="50%" />}
      >
        <div className="container">
          <div className="row">
            {foundItems.map((item, index) => {
              return (
                <div key={item.item_id} className="col-md-3">
                  <Card key={index * Math.random()} item={item} index={index} />
                </div>
              );
            })}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
}
