import { useState } from 'react';
import AuthService from '.';
import axios from 'axios';
import authHeader from './authHeader';
import { translator } from '../../core/languages/translator';
import { addItem, getMessages, updateItem } from '../../core/userStore';
import { toast } from 'react-toastify';
const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * This is a custom hook that can be used to submit a form and simulate an API call
 * It uses Math.random() to simulate a random success or failure, with 50% chance of each
 */
const useSubmit = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const submit = async (url, data) => {
    const random = Math.random();
    setLoading(true);
    switch (url) {
      case 'login':
        try {
          await wait(1000);

          AuthService.login(data)
            .then((x) => {
              return setResponse({
                type: 'success',
                message: translator(`Logged In`),
              });
            })
            .catch((x) => {
              return setResponse({
                type: 'error',
                message: translator(
                  'Email is not valid, please make sure to confirm your email before trying again.'
                ),
              });
            });
        } catch (x) {
          setResponse({
            type: 'error',
            message: translator('Something Wrong'),
          });
        } finally {
          setLoading(false);
        }

        break;
      case 'register':
        try {
          await wait(1000);

          AuthService.register(data)
            .then((x) => {
              return setResponse({
                type: 'success',
                message: translator(
                  x?.data?.message ||
                    x?.status ||
                    `Successful, please use your credentials to login in.`
                ),
              });
            })
            .catch((x) => {
              console.log(x);
              return setResponse({
                type: 'error',
                message: translator(x?.data?.message || x?.status || 'Something Wrong'),
              });
            });
        } catch (x) {
          setResponse({
            type: 'error',
            message: translator(x?.data?.message || x?.status || 'Something Wrong'),
          });
        } finally {
          setLoading(false);
        }
        break;
      case 'sendmessage':
        try {
          await wait(1000);
          return axios.post(`${process.env.REACT_APP_AUTH}/email`, data).then((response) => {
            if (response.status === 200) {
              toast(translator('Message Sent'), { type: 'success' });
              getMessages();
              return setResponse({
                type: 'success',
                message: translator('Message Sent'),
              });
            } else {
              toast(translator('Something Wrong'), { type: 'error' });
              return setResponse({
                type: 'error',
                message: translator('Something Wrong'),
              });
            }
          });
        } catch (x) {
          return setResponse({
            type: 'error',
            message: translator(x?.data?.message || x?.status || 'Something Wrong'),
          });
        } finally {
          setLoading(false);
        }
      case 'addnewitem':
        try {
          await wait(1000);
          return new Promise((resolve) => {
            return addItem(data)
              .then((x) => {
                toast(`${translator('Item Added')}`);
                resolve(
                  setResponse({
                    type: 'success',
                    message: translator('Item Added'),
                  })
                );
              })
              .catch((x) => {
                resolve(
                  setResponse({
                    type: 'error',
                    message: translator(x?.data?.message || x?.status || 'Something Wrong'),
                  })
                );
              });
          });
        } catch (x) {
          return setResponse({
            type: 'error',
            message: translator(x?.data?.message || x?.status || 'Something Wrong'),
          });
        } finally {
          setLoading(false);
        }
      case 'updateItem':
        try {
          await wait(1000);
          return new Promise((resolve) => {
            return updateItem(data)
              .then((x) => {
                toast(`${translator('Updated')}`);
                resolve(
                  setResponse({
                    type: 'success',
                    message: translator('Updated'),
                  })
                );
              })
              .catch((x) => {
                resolve(
                  setResponse({
                    type: 'error',
                    message: translator(x?.data?.message || x?.status || 'Something Wrong'),
                  })
                );
              });
          });
        } catch (x) {
          return setResponse({
            type: 'error',
            message: translator(x?.data?.message || x?.status || 'Something Wrong'),
          });
        } finally {
          setLoading(false);
        }
      case 'contactus':
        try {
          await wait(1000);
          return new Promise((resolve) => {
            return axios.post(`${process.env.REACT_APP_AUTH}/contact`, data).then((response) => {
              if (response.status === 200) {
                toast(translator('Message Sent'), { type: 'success' });
                getMessages();
                return setResponse({
                  type: 'success',
                  message: translator('Message Sent'),
                });
              } else {
                toast(translator('Something Wrong'), { type: 'error' });
                return setResponse({
                  type: 'error',
                  message: translator('Something Wrong'),
                });
              }
            });
          });
        } catch (x) {
          return setResponse({
            type: 'error',
            message: translator(x?.data?.message || x?.status || 'Something Wrong'),
          });
        } finally {
          setLoading(false);
        }
      case 'sendconfirmation':
        try {
          await wait(1000);
          return new Promise((resolve) => {
            return axios
              .post(`${process.env.REACT_APP_AUTH}/email/confirmation-send`, data)
              .then((response) => {
                if (response.status === 200) {
                  toast(translator('Message Sent'), { type: 'success' });
                  getMessages();
                  return setResponse({
                    type: 'success',
                    message: translator('Message Sent'),
                  });
                } else {
                  toast(translator('Something Wrong'), { type: 'error' });
                  return setResponse({
                    type: 'error',
                    message: translator('Something Wrong'),
                  });
                }
              });
          });
        } catch (x) {
          return setResponse({
            type: 'error',
            message: translator(x?.data?.message || x?.status || 'Something Wrong'),
          });
        } finally {
          setLoading(false);
        }
      case 'emailconfirmation':
        try {
          await wait(1000);
          return new Promise((resolve) => {
            return axios
              .post(`${process.env.REACT_APP_AUTH}/email/confirmation`, data)
              .then((response) => {
                if (response.status === 200) {
                  toast(translator('Email Confirmed'), { type: 'success' });
                  return submit('login', data);
                } else {
                  toast(translator('Something Wrong'), { type: 'error' });
                  return setResponse({
                    type: 'error',
                    message: translator('Something Wrong'),
                  });
                }
              });
          });
        } catch (x) {
          return setResponse({
            type: 'error',
            message: translator(x?.data?.message || x?.status || 'Something Wrong'),
          });
        } finally {
          setLoading(false);
        }
      default:
        try {
          await wait(1000);

          axios
            .post(url, data, authHeader)
            .then((x) => {
              return setResponse({
                type: 'success',
                message: translator(x?.data?.message || x?.status || `Successful!`),
              });
            })
            .catch((x) => {
              return setResponse({
                type: 'error',
                message: translator(x?.data?.message || x?.status || 'Something Wrong'),
              });
            });
        } catch (x) {
          setResponse({
            type: 'error',
            message: translator(x?.data?.message || x?.status || 'Something Wrong'),
          });
        } finally {
          setLoading(false);
        }
        break;
    }
  };

  return { isLoading, response, submit };
};

export default useSubmit;
