import React, { Component, createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'transitions';
import './App.css';
import AuthService from './components/services/index';
import Login from './components/login/Login';
import Register from './components/Register/Register';
import Home from './components/home/Home';
import Sell from './components/sell/Sell';
import Item from './components/item/Item';
import Account from './components/account/Account';
import Search from './components/search/Search';
import Edit from './components/edit/Edit';
import Message from './components/message/Message';
import AuthVerify from './core/auth-verify';
// import EventBus from './core/EventBus';
import { getCurrentUser, getOffers, userStore } from './core/userStore';
import Header from './components/header/Header';
import Footer from './core/Footer';
import { ThemeProvider } from './core/ThemeContext';
import LogOutPage from './core/LogOutPage';
import ContactUs from './core/ContactUs';
import Loading from './core/Loading';
import Policy from './core/Policy';
import AboutUs from './core/Aboutus';
import Messages from './components/messages/Messages';
import Offers from './components/offers/Offers';
import ChatHome from './core/pages/chat/home';
import Chat from './core/pages/chat';
import { SocketContext, socket } from './core/socket/Wsocket';

export default function App() {
  const [showAdminBoard, setshowAdminBoard] = useState(false);
  const [showModeratorBoard, setshowModeratorBoard] = useState(false);
  const [currentUser, setcurrentUser] = useState([]);
  const [loading, setloading] = useState([]);
  const theme = userStore((state) => state.theme);
  const senderName = userStore((state) => state.senderName);
  const errorMsgConnection = userStore((state) => state.errorMsgConnection);
  const language = userStore((state) => state.language);
  const userId = userStore((state) => state.userId);
  const username = userStore((state) => state.senderName);
  const [room, setRoom] = useState(''); // Add this

  const setRoomCallback = (x) => {
    setRoom(x);
  };
  useEffect(() => {
    setloading(true);
    const user = getCurrentUser();
    setcurrentUser(user);
    setloading(false);
    if (localStorage.getItem('language') && localStorage.getItem('language') !== '') {
      userStore.setState({
        language: localStorage.getItem('language'),
      });
    }
    if (localStorage.getItem('theme') && localStorage.getItem('theme') !== '') {
      userStore.setState({
        theme: localStorage.getItem('theme'),
      });
    }
    // setshowModeratorBoard(user.roles.includes('ROLE_MODERATOR'));
    // setshowAdminBoard(user.roles.includes('ROLE_ADMIN'));
  }, []);

  const logOut = () => {
    AuthService.logout();
    setcurrentUser(undefined);
    setshowModeratorBoard(false);
    setshowAdminBoard(false);
  };
  if (loading) {
    return <Loading width={'100%'} />;
  }

  return (
    <ThemeProvider>
      <SocketContext.Provider value={socket}>
        <div
          className="col-md-12 maxw-100"
          style={{
            backgroundColor: theme === 'light' ? 'lightgray' : 'white',
            minHeight: '100vh',
            direction: language === 'ar' ? 'rtl' : 'ltr',
          }}
          language={language}
        >
          <main className="col-md-12 maxw-100">
            <div className="col-md-12 maxw-100">
              <Header
                logoutCallback={logOut}
                currentuser={currentUser}
                theme={theme}
                language={language}
              />

              <div className="col-md-12 container" style={{ minHeight: '80vh' }}>
                {/* {errorMsgConnection !== '' ? (
                <span className="alert dismisable alert-dismisable">{errorMsgConnection}</span>
              ) : (
                []
              )} */}
                <Routes>
                  <Route path="/edit/:item_id" element={<Edit />} />
                  <Route path="/search/:item" element={<Search />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/message" element={<Message />} />
                  <Route path="/item/:id" element={<Item />} />
                  <Route path="/sell" element={<Sell />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/profile" element={<Account />} />
                  <Route path="/logout" element={<LogOutPage />} />
                  <Route exact path="/search" element={<Search />} />
                  <Route path="/contactus" element={<ContactUs />} />
                  <Route path="/policy-and-terms" element={<Policy />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/messages" element={<Messages />} />
                  <Route path="/myoffers" element={<Offers />} />
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/chat"
                    element={<ChatHome username={username} room={room} setRoom={setRoomCallback} />}
                  />
                  <Route path="/chat-room" element={<Chat username={username} room={room} />} />
                  <Route path="/*" element={<Home />} />
                </Routes>
              </div>
              <AuthVerify logOut={logOut} />
            </div>
          </main>
          <Footer theme={theme} />
        </div>
      </SocketContext.Provider>
    </ThemeProvider>
  );
}
