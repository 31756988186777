import React, { useEffect, useState } from 'react';
import { checkUser, getItems, userStore } from '../../core/userStore';
import { Link } from 'react-router-dom';
import { translator } from '../../core/languages/translator';
import InfiniteScroll from 'react-infinite-scroll-component';
import Card from '../assets/card';
import Loading from '../../core/Loading';
import metaTags from '../../core/MetaTags';
export default function Home(props) {
  const items = userStore((state) => state.items);
  const [posts, setPosts] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  useEffect(() => {
    setMounted(mounted);
    getItems(page, 10);
    metaTags({
      sitename: translator('Kitab4less'),
      title: 'Kitab 4 Less',
      author: `كتاب بأقل | Kitab 4 Less`,
      description:
        'كتاب بأقل | Kitab 4 Less | كتاب بسعر أقل ، جميع الكتب ، مستعملة أو جديدة ، يمكن العثور عليها بأقل الأسعار.',
      keywords:
        'kitab, store, shop, marketplace, market, for, less, all books, used, new, school, personal, free, prices, jordan, qatar, saudi arabia, bahrain, middle east, pre owned,كتاب ، أقل ، جميع الكتب ، مستعمل ، جديد ، مدرسي ، شخصي ، مجاني ، أسعار ، الأردن ، قطر ، السعودية ، البحرين ، الشرق الأوسط ، مملوك مسبقًا',
      image: 'https://kitab4less.com/logo.png',
    });
  }, []);
  useEffect(() => {
    setMounted(mounted);
    setPosts(items);
    setLoading(false);
  }, [items]);
  const fetchMoreData = async () => {
    getItems(page + 1, 10).then((data) => {
      setPage(page + 1);
      setLoading(true);
      setPosts(posts.concat(data));
      setLoading(false);
      console.clear();
    });
  };

  return (
    <div className="container my-3">
      {loading && <Loading width="100%" />}
      <InfiniteScroll
        dataLength={items?.length}
        next={fetchMoreData}
        hasMore={posts?.length !== totalResults}
        loader={loading && <Loading width="50%" />}
      >
        <div className="container">
          <div className="row">
            {posts.map((item, index) => {
              return (
                <div key={item.item_id} className="col-md-3">
                  <Card key={index * Math.random()} item={item} index={index} />
                </div>
              );
            })}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
}
