import React from 'react';
import { translator } from './languages/translator';

class ConfirmationButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
      classButtonName: 'buttonForm buttonAlert',
      classDialogName: 'dialog_alert',
      query: this.props.query,
      param: 'del',
      param_id: '-1',
      view: 'button',
    };
  }

  showDialog() {
    this.setState({
      view: 'query',
    });
  }

  onClickYes() {
    this.setState({
      view: 'button',
    });
    this.props.onConfirm();
  }

  onClickNo() {
    this.setState({
      view: 'button',
    });
  }

  render() {
    if (this.state.view == 'button') {
      return (
        <div
          style={{ width: '100%' }}
          className="button-85 sell-btn mt-2 text-center"
          onClick={this.showDialog.bind(this)}
        >
          {this.state.title}
        </div>
      );
    }

    if (this.state.view == 'query') {
      return (
        <div className={this.state.classDialogName}>
          <div>{this.state.title}</div>
          <div className="container">
            <div>{this.state.query}</div>
            <div
              className=" button-85 sell-btn mt-2"
              onClick={this.onClickYes.bind(this)}
              data-bs-dismiss="modal"
            >
              {translator('YES')}
            </div>
            <div
              className=" button-85 sell-btn mt-2"
              onClick={this.onClickNo.bind(this)}
              data-bs-dismiss="modal"
            >
              {translator('No')}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ConfirmationButton;
