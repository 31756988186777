import React, { useEffect, useState } from 'react';
import { getOneItem, offerResponseSubmit, userStore } from '../../../core/userStore';
import { translator } from '../../../core/languages/translator';
import { Link } from 'react-router-dom';

export default function ItemStatusUpdate(props) {
  const [item, setItem] = useState([]);
  const userId = userStore((s) => s.userId);
  useEffect(() => {
    getOneItem(props?.itemid).then((x) => setItem(x));
  }, [props]);

  const offerResponse = (response) => {
    offerResponseSubmit(userId, props?.offerid, response);
  };
  if (item[0]?.user_id !== userId) {
    return <></>;
  }

  return (
    <div className="row m-2">
      {' '}
      <div className="col-6">
        <button
          style={{ width: '100%' }}
          type="button"
          data-bs-dismiss="modal"
          className=" button-85 sell-btn mt-2"
          onClick={() => offerResponse('accepted')}
        >
          {translator('Accept')} {props?.price}
        </button>
      </div>
      <div className="col-6">
        <button
          style={{ width: '100%' }}
          type="button"
          data-bs-dismiss="modal"
          className=" button-85 sell-btn mt-2"
          onClick={() => offerResponse('rejected')}
        >
          {translator('Reject')} {props?.price}
        </button>
      </div>
    </div>
  );
}
