/* eslint-disable react/no-unknown-property */
import React, { Component, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './Message.css';
import { create_slug, getOffers, userStore } from '../../core/userStore';
import Loading from '../../core/Loading';
import Message from '../message/Message';
import { translator } from '../../core/languages/translator';

export default function Offers(props) {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState(0);
  const [item_id, setitem_id] = useState(0);
  const [title, settitle] = useState('');
  const [owner, setOwner] = useState('');
  const [selectedMessage, setselectedMessage] = useState([]);
  const [inboxList, setinboxList] = useState([]);
  const sender = userStore((state) => state.userId);
  const senderName = userStore((state) => state.senderName);
  const myoffers = userStore((state) => state.myoffers);
  const [messageValue, setMessageValue] = useState([]);
  const [activeTab, setactiveTab] = useState([]);
  const [info, setInfo] = useState([]);
  const bottomEl = useRef(null);

  useEffect(() => {
    if (loading && sender > 0) {
      getOffers(sender, 0, 99);
    }
  }, [loading]);
  function mergemyoffers(data) {
    // Reduce the array based on shared options of 'option1' and 'option2'
    return data.reduce((result, obj) => {
      // Find an existing entry in the result array with the same options
      const existingEntry = result.find(
        (entry) =>
          (entry.sender.includes(obj.sender) &&
            entry.reciever.includes(obj.reciever) &&
            entry.item_id === obj.item_id) ||
          (entry.reciever.includes(obj.sender) &&
            entry.sender.includes(obj.reciever) &&
            entry.item_id === obj.item_id)
      );

      if (existingEntry) {
        existingEntry.myoffers.push(obj.message);
        existingEntry.prices.push(obj.price);
        existingEntry.reciever.push(obj.reciever);
        existingEntry.sender.push(obj.sender);
        existingEntry.currency.push(obj.currency);
        existingEntry.created_at.push(obj.created_at);
        existingEntry.senderNames.push(obj.senderName);
        (existingEntry.senderId = obj.sender !== sender ? obj.sender : existingEntry.senderId),
          (existingEntry.senderName =
            obj.senderName !== senderName ? obj.senderName : existingEntry.senderName);
      } else {
        result.push({
          item_id: obj.item_id,
          senderId: obj.sender,
          senderName: obj.senderName !== senderName ? obj.senderName : 'You',
          senderNames: [obj.senderName],
          sender: [obj.sender],
          created_at: [obj.created_at],
          currency: [obj.currency],
          title: obj.title,
          prices: [obj.price],
          status: obj.status,
          reciever: [obj.reciever],
          myoffers: [obj.message],
        });
      }
      return result;
    }, []);
  }
  useEffect(() => {
    setLoading(true);
    setinboxList(mergemyoffers(myoffers));
    setTimeout(() => {
      onMessageClick(
        selectedMessage?.item_id,
        selectedMessage?.senderId,
        selectedMessage?.title,
        selectedMessage?.senderName
      );
      setLoading(false);
    }, 300);
  }, [myoffers]);
  useEffect(() => {
    setTimeout(() => {
      bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }, [messageValue]);
  const onMessageClick = (item_id, senderId, reciever, title, senderNameT) => {
    setMessageValue([]);
    setactiveTab(title + senderNameT + senderId + reciever + item_id);
    let index = inboxList.findIndex(
      (y) =>
        y?.item_id === item_id &&
        y?.title === title &&
        y?.senderName === senderNameT &&
        y?.senderId === senderId &&
        y?.reciever === reciever
    );
    const x = inboxList[index];
    setMessageValue(
      <div
        className="message"
        key={x?.title + senderNameT + Math.random()}
        id={create_slug(`${x?.title}-${x?.item_id}`)}
      >
        {x?.myoffers.map((msg, msgindex) => (
          <p
            className={`border text-white rounded-3 mb-3 p-2 ${
              x?.sender[msgindex] === sender ? '  bg-dark ' : ' bg-success'
            }`}
          >
            <p>
              {translator('Status')}:
              <b>
                {x?.status === 0 || x?.status === '0' ? (
                  <span className="badge bg-danger text-white">{translator('Rejected')}</span>
                ) : x?.status === 1 || x?.status === '1' ? (
                  <span className="badge bg-warning text-black">{translator('Pending')}</span>
                ) : (
                  <span className="badge bg-success  text-white">{translator('Accepted')}</span>
                )}
              </b>
            </p>
            <p>
              <b>
                {x?.sender[msgindex] === sender ? 'You' : x?.senderNames[msgindex]} -{' '}
                <small>{new Date(x?.created_at[msgindex]).toGMTString()}</small>
              </b>
            </p>
            <p>
              {translator('Price')}:
              <b>
                {x?.prices[msgindex]} {x?.currency[msgindex]}
              </b>
            </p>
            <p>
              {translator('Message')}:<b>{msg}</b>
            </p>
          </p>
        ))}
      </div>
    );
    setInfo({
      item_id: item_id,
      title: title,
      status: x?.status,
      senderId: x?.senderId,
      currency: x?.currency[x?.currency.length - 1],
      created_at: x?.created_at[x?.created_at.length - 1],
      price: x?.prices[x?.prices.length - 1],
      senderName: senderName,
    });
  };
  return loading ? (
    <Loading width={'100%'} />
  ) : sender > 0 && myoffers && myoffers.length ? (
    <>
      <div className="container-fluid text-dark bg-light no-style nostyle" id="myoffers-tabContent">
        <div className="row align-items-start" myofferslist={myoffers}>
          <section className="col-md-4 list sticky-top d-block">
            <h2>{translator('Sent')}</h2>
            <hr />
            <div className="list-group">
              {inboxList.length > 0 ? (
                inboxList.map((x, index) => (
                  <button
                    key={x?.title + index + Math.random()}
                    className={` ${
                      activeTab ===
                      x?.title + x?.senderName + x?.senderId + x?.reciever + x?.item_id
                        ? 'list-group-item list-group-item-action active'
                        : 'list-group-item list-group-item-action '
                    }`}
                    onClick={() => {
                      setselectedMessage(x);
                      setInfo({
                        item_id: item_id,
                        title: x?.title,
                        senderId: x?.senderId,
                        reciever: x?.reciever,
                        price: x?.prices,
                        currency: x?.currency,
                        created_at: x?.created_at,
                        senderName: x?.senderName,
                        senderNames: x?.senderNames,
                      });
                      return onMessageClick(
                        x?.item_id,
                        x?.senderId,
                        x?.reciever,
                        x?.title,
                        x?.senderName
                      );
                    }}
                  >
                    {`${x?.title}`}
                    <p>
                      {translator('Price')}:
                      <b>
                        {x?.prices[0]} {x?.currency[0]}
                      </b>
                    </p>
                    <p>
                      {translator('Status')}:
                      <b>
                        {x?.status === 0 || x?.status === '0' ? (
                          <span className="badge bg-danger text-white">
                            {translator('Rejected')}
                          </span>
                        ) : x?.status === 1 || x?.status === '1' ? (
                          <span className="badge bg-warning text-black">
                            {translator('Pending')}
                          </span>
                        ) : (
                          <span className="badge bg-success  text-white">
                            {translator('Accepted')}
                          </span>
                        )}
                      </b>
                    </p>
                    {isNaN(x?.created_at[0]) === false ? (
                      <small>{new Date(Date.parse(x?.created_at[0])).toString()}</small>
                    ) : (
                      []
                    )}{' '}
                  </button>
                ))
              ) : (
                <span>{`${translator('No myoffers yet')}`}</span>
              )}
            </div>
          </section>
          <section className="col-md-8 border d-block">
            <div>
              <h2>{translator('Offer')}</h2>
              <hr />
              {messageValue}
            </div>

            {/* {info.item_id ? (
              info.item_id && (
                <>
                  <div id="replymsg">
                    <Message
                      myoffersentCallback={() => {
                        getOffers();
                      }}
                      direct={true}
                      item_id={info.item_id}
                      title={info.title}
                      owner={info.senderId}
                      price={info.price}
                      currency={info.currency}
                      created_at={info.created_at}
                      senderName={senderName}
                      senderNames={info?.senderNames}
                    />
                  </div>
                  <div ref={bottomEl}></div>
                </>
              )
            ) : (
              <span>{`${translator('No myoffers yet')}`}</span>
            )} */}
          </section>
        </div>
      </div>
    </>
  ) : sender > 0 ? (
    []
  ) : (
    <a href="/login">{`${translator('Login')}`}</a>
  );
}
