import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import useSubmit from '../services/useSubmit';
import { ChakraProvider } from '@chakra-ui/react';
import { userStore } from '../../core/userStore';
import { Navigate } from 'react-router-dom';
import { translator } from '../../core/languages/translator';
import { toast } from 'react-toastify';
import Loading from '../../core/Loading';
import { socket } from '../../core/socket/Wsocket';

export default function Login(props) {
  const { isLoading, response, submit } = useSubmit();
  const [onOpen, setOnOpen] = useState([]);
  const isLoggedin = userStore((state) => state.loggedIn);
  const userId = userStore((state) => state.userId);

  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
    },
    onSubmit: (values) => {
      setOnOpen({ message: 'Loading' });
      submit('login', values);
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(translator('Invalid email address'))
        .required(translator('Required')),
      password: Yup.string().required(translator('Required')),
    }),
  });

  useEffect(() => {
    if (response) {
      setOnOpen({ type: response.type, message: response.message });
      toast(response.message);
      if (response.type === 'success') {
        formik.resetForm();
        socket.disconnect();
        window.location.href = '/profile';
      }
    }
  }, [response]);

  if (userId !== 0) {
    return <Navigate to={'/account'} />;
  }
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div
        className="container p-5"
        style={{
          // eslint-disable-next-line react/prop-types
          backgroundColor: props.theme === 'dark' ? 'black' : 'white',
          // eslint-disable-next-line react/prop-types
          color: props.theme === 'dark' ? 'white' : 'black',
        }}
      >
        <ChakraProvider>
          <VStack alignItems="flex-start">
            <Heading as="h1" id="contactus">
              {translator('Login')}
            </Heading>
            <Box p={6} rounded="md" w="100%">
              {onOpen?.message}
              <form onSubmit={formik.handleSubmit}>
                <VStack spacing={4}>
                  <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                    <FormLabel htmlFor="email">{translator('Email Address')}</FormLabel>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      {...formik.getFieldProps('email')}
                    />
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                    <FormLabel htmlFor="email">{translator('Password')}</FormLabel>
                    <Input
                      id="password"
                      name="password"
                      type="password"
                      {...formik.getFieldProps('password')}
                    />
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                  </FormControl>

                  <Button
                    type="submit"
                    className="button-85 "
                    colorScheme="purple"
                    width="full"
                    isLoading={isLoading}
                  >
                    {translator('Submit')}
                  </Button>
                </VStack>
              </form>
            </Box>
          </VStack>
        </ChakraProvider>
      </div>
    </>
  );
}
